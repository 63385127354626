import React, { useEffect, useRef } from "react";
import arrow from "../../images/icones/arrow.png";
import TextAreaWithLabel from "../../Components/TextAreaWithLabel";
import OrganizationType from "./Components/OrganizationType";
import ResourceLink from "./Components/ResourceLink";
import useStore from "../../API/store";
function NewTalentHub({ handleGoBack, setSelectedStatus,setShowCooptModal,setOnInvite, dioData }) {
  const {
    setLoading,
    setError,
    createTalentsHub,
    talentsHubData,
    nameDO,
    setNameDO,
    handleCreateDO,
    setPitch,
    setWhatToDo,
    setWhyImportant,
    setOrganizationType,
    setLink,
    pitch,
    whatToDo,
    whyImportant,
    organizationType,
    showResourceInput,
    link,
    setShowResourceInput,
  } = useStore();

  const isValidUrl = (url) => {
    const regex = /^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/gm;
    return regex.test(url);
  };

  useEffect(()=>{
    console.log(talentsHubData, "<============================ talentsHubData from the component");
    
  },[talentsHubData])
  const validateForm = () => {
    // Vérification des champs obligatoires
    return (
      nameDO.trim() !== "" && // Nom de l'organisation
      pitch.trim() !== "" && // Pitch
      whatToDo.trim() !== "" && // What to do
      whyImportant.trim() !== "" && // Why important
      organizationType.trim() !== "" && // Type d'organisation
      (link === "" || isValidUrl(link))
    );
  };

  const userId = localStorage.getItem("userId");
  const onSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      alert("Please fill all required fields.");
      return;
    }
  
    const payload = {
      nameDO: nameDO,
      descriptionDO: pitch,
      CeoDoId: Number(userId),
      typeActivityDO: organizationType,
      linkDO: link,
      makeitbetterDO: whyImportant,
    };
    setLoading(true);
  
    const createRequest = createTalentsHub(payload);
    console.log(createRequest);  // Vérifie ce que retourne createTalentsHub
  
    if (createRequest && typeof createRequest.then === 'function') {
      createRequest
        .then(() => {
          console.log("createTalentsHub successfully submitted!");
          setLoading(false);
          setShowCooptModal(true)
          setNameDO("");
          setPitch("");
          setOrganizationType("");
          setLink("");
          setWhyImportant("");
          handleGoBack();
          setSelectedStatus("all");
        })
        .catch((error) => {
          setError(error.message);  // Assurer qu'on passe une chaîne de caractères
          setLoading(false); // Stop loading in case of error
        });
    } else {
      setLoading(false);
      console.error('createTalentsHub did not return a Promise');
    }
  };
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    switch (name) {
      case "organizationName":
        setNameDO(value);
        break;
      case "pitch":
        setPitch(value);
        break;
      case "whatToDo":
        setWhatToDo(value);
        break;
      case "whyImportant":
        setWhyImportant(value);
        break;
      default:
        break;
    }
  };
  const handleInputLinkChange = (e) => {
    setLink(e.target.value); // Mettre à jour le store avec la nouvelle valeur
  };
  const handleTypeSelect = (type) => {
    setOrganizationType(type);
  };

  const toggleResourceInput = () => {
    setShowResourceInput(!showResourceInput)
  };

  const newTalentHubViewRef = useRef();
  useEffect(() => {
    if (newTalentHubViewRef.current) {
      newTalentHubViewRef.current.scrollIntoView({
        block: "start",
        behavior: "instant",
      });
    }
  }, []);
  return (
    <div
      ref={newTalentHubViewRef}
      className="w-1/2 bg-rightGreyBg p-32 overflow-y-auto max-h-[calc(110vh-64px)]"
    >
      <div className="max-w-2xl mx-auto">
        <img
          src={arrow}
          alt="retour"
          className="w-12 h-12 cursor-pointer"
          onClick={handleGoBack}
        />

        <div className="space-y-12">
          <div>
            <h2 className="text-sm uppercase mb-2 mt-12 font-silka-mono">
              Registration
            </h2>
            <h1 className="text-4xl font-bold mb-8 font-silka-mono mt-6">
              Create your
              <br />
              new Collab Space
            </h1>
          </div>

          <form onSubmit={onSubmit} className="space-y-12">
            <div>
              <input
                type="text"
                name="organizationName"
                placeholder="NAME OF YOUR COLLAB SPACE"
                className="w-[80%] px-4 py-3 border-2 border-black bg-transparent placeholder-black/70 rounded-xl outline-none font-silka-mono"
                value={nameDO}
                onChange={handleInputChange}
              />
            </div>

            <TextAreaWithLabel
              label={
                <>
                  Pitch
                  <br /> your Collab Space <br />
                </>
              }
              name="pitch"
              placeholder="Provide a brief description"
              value={pitch}
              onChange={handleInputChange}
              bg="bg-rightGreyBg"
            />

            <ResourceLink
              handleLinkClick={toggleResourceInput}
              isInputVisible={showResourceInput}
              link={link}
              handleInputChange={handleInputLinkChange}
            />

            <TextAreaWithLabel
              label={
                <>
                  What do you want
                  <br />
                  to do <br />
                </>
              }
              name="whatToDo"
              placeholder="Provide a brief description"
              value={whatToDo}
              onChange={handleInputChange}
              bg="bg-rightGreyBg"
            />

            <TextAreaWithLabel
              label={
                <>
                  Why is it <br />
                  important ?
                </>
              }
              name="whyImportant"
              placeholder="Provide a brief description"
              value={whyImportant}
              onChange={handleInputChange}
              bg="bg-rightGreyBg"
            />

            <OrganizationType
              selected={organizationType}
              onSelect={handleTypeSelect}
            />
{/* 
            <div className="flex items-start space-x-3">
              <input
                type="checkbox"
                name="acceptTerms"
                className="mt-1 border-2 border-black rounded-none"
                checked={acceptTerms}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    acceptTerms: e.target.checked,
                  }))
                }
              />
              <label className="text-sm">
                I accept the general terms and conditions of the Thankword
                <br />
                model for organizations
              </label>
            </div> */}

            <button
              type="submit"
              className="px-8 py-4 bg-black text-white rounded-full hover:bg-black/90 transition-colors mt-6 font-silka-mono"
            >
              MAKE IT HAPPEN
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default NewTalentHub;
