// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-container {
    position: relative;
    background-color:  #082247;
    padding: 1%;
    margin-left: 30px;
    margin-right: 30px;
    height: 11vh;
    margin-bottom: 0%;

  }

  .input-submit {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color:  #5CB7BBA3;
    padding: 0.5%; 
    width: 90%;
    height: 5.05vh;
    border-radius: 20px;
    margin: auto;
  }


  .message-input {
    flex-grow: 2;
    padding: 1%; 
    background-color: transparent; 
    color: white; 
    border: none;
    font-size: large;
    outline: none; 
  }

  .message-input ::placeholder{
    color: white;
    font-size: 1.5vw;
  }
  
  .send-button {
    width: 18%;
    height: 3.8vh;
    padding: 0.5%;
    font-size: 1vw; 
    border: none; 
    background-color: white; 
    color: black; 
    cursor: pointer; 
    border-radius: 40px;
    font-weight: bold;
    align-items: center;
  }
  
  .send-button:hover{
    background-color: gray;
    color: black;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/ExecutionBoard/ExecutionMessaging.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,0BAA0B;IAC1B,WAAW;IACX,iBAAiB;IACjB,kBAAkB;IAClB,YAAY;IACZ,iBAAiB;;EAEnB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,4BAA4B;IAC5B,aAAa;IACb,UAAU;IACV,cAAc;IACd,mBAAmB;IACnB,YAAY;EACd;;;EAGA;IACE,YAAY;IACZ,WAAW;IACX,6BAA6B;IAC7B,YAAY;IACZ,YAAY;IACZ,gBAAgB;IAChB,aAAa;EACf;;EAEA;IACE,YAAY;IACZ,gBAAgB;EAClB;;EAEA;IACE,UAAU;IACV,aAAa;IACb,aAAa;IACb,cAAc;IACd,YAAY;IACZ,uBAAuB;IACvB,YAAY;IACZ,eAAe;IACf,mBAAmB;IACnB,iBAAiB;IACjB,mBAAmB;EACrB;;EAEA;IACE,sBAAsB;IACtB,YAAY;EACd","sourcesContent":[".input-container {\n    position: relative;\n    background-color:  #082247;\n    padding: 1%;\n    margin-left: 30px;\n    margin-right: 30px;\n    height: 11vh;\n    margin-bottom: 0%;\n\n  }\n\n  .input-submit {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    background-color:  #5CB7BBA3;\n    padding: 0.5%; \n    width: 90%;\n    height: 5.05vh;\n    border-radius: 20px;\n    margin: auto;\n  }\n\n\n  .message-input {\n    flex-grow: 2;\n    padding: 1%; \n    background-color: transparent; \n    color: white; \n    border: none;\n    font-size: large;\n    outline: none; \n  }\n\n  .message-input ::placeholder{\n    color: white;\n    font-size: 1.5vw;\n  }\n  \n  .send-button {\n    width: 18%;\n    height: 3.8vh;\n    padding: 0.5%;\n    font-size: 1vw; \n    border: none; \n    background-color: white; \n    color: black; \n    cursor: pointer; \n    border-radius: 40px;\n    font-weight: bold;\n    align-items: center;\n  }\n  \n  .send-button:hover{\n    background-color: gray;\n    color: black;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
