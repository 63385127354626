import React from "react";
import CooptIcon from "../../../../images/icones/CooptIcon.png";
import CooptThanks from "../../../../images/icones/CooptThanks.png";

const CooptModal = ({
  setIsSplitView,
  setOnInvite,
  onInvite,
  closeModal,
  setIsCreating,
  setCreationType,
  setShowCooptModal
}) => {
  const handleInvite = () => {
    setOnInvite(!onInvite);
    setShowCooptModal(false);  // Ferme le modal ici
    closeModal(); // Si tu veux aussi fermer le modal via cette fonction
    setIsSplitView(true);
    setIsCreating(true);
    setCreationType("cooptation");
  };

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-30"
      onClick={closeModal}  // Ce handler ferme le modal si tu cliques à l'extérieur
    >
      <div
        className="bg-black p-5 rounded-[20px] shadow-lg w-[700px] h-[500px]"
        onClick={(e) => e.stopPropagation()}  // Empêche la fermeture du modal lorsqu'on clique à l'intérieur
      >
        <div className="flex justify-end relative top-[10px] right-[10px]">
          <button
            className="font-silka-mono text-slate-300 text-[11px] rounded uppercase underline cursor-pointer hover:text-white transition-colors duration-300"
            onClick={() => {
              closeModal();  // Ferme le modal
              setShowCooptModal(false);  // Ferme le modal
            }}
          >
            Next time
          </button>
        </div>
        <p className="mb-4 mt-10 text-white text-center font-silka-mono font-semibold text-2xl">
          Expand your community <br /> for new opportunities
        </p>
        <div className="relative flex justify-center text-center mb-5">
          <div className="w-[130px] h-[130px] rounded-lg flex items-center justify-center mt-6">
            <img className="w-full h-auto" src={CooptIcon} />
          </div>
          <div className="absolute right-[160px] top-[50px] rounded-full px-4 py-1">
            <img className="w-full h-auto" src={CooptThanks} />
          </div>
        </div>
        <div className="flex justify-center items-center m-6">
          <p className="mb-4 mt-2 text-white text-center font-silka-mono">
            Invite a talented friend <br /> to thankward
          </p>
        </div>
        <div className="flex justify-center mb-4 mt-2 text-white text-center font-silka-mono">
          <button
            onClick={handleInvite}
            className="border border-white text-white px-5 py-2.5 rounded-full bg-transparent cursor-pointer transition-colors duration-300 ease-in-out hover:bg-white hover:text-black"
          >
            INVITE
          </button>
        </div>
      </div>
    </div>
  );
};

export default CooptModal;
