import React from "react";
import useLogout from "../../Profile-Help-Login/useLogout";

import { Link } from "react-router-dom";

import logo from "../../../images/logo.png";
import mourad from "../../../images/icones/personna.png";

import dec from "../../../images/icones/deconnexion.webp";

const Header = () => {
  const { logout } = useLogout();
  return (
    <header className="w-full flex items-end justify-between h-[80px] pl-2 pr-2">
      <div className="flex items-center">
        <Link to="/homepage">
          <img src={logo} alt="Thankward Logo" className="h-10 w-auto" />
        </Link>
      </div>

      <div className="flex space-x-4">
        <div className="h-10 w-10 rounded-full">
          <img onClick={logout} src={dec} alt="deconnexion" className="w-10 h-10 cursor-pointer" />
        </div>
        <img src={mourad} alt="profile" className="h-10 w-10 rounded-full" />
      </div>
    </header>
  );
};

export default Header;
