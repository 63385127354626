import React from "react";

export const ActionMenu = ({ onAchievementClick, sharedStyles }) => (
  <div className="absolute right-full mr-[100px] -top-4 w-[352px] h-[389px] bg-black shadow-lg rounded-2xl overflow-hidden p-4">
    <ul className="h-full flex flex-col justify-between p-6">
      <li
        className={`${sharedStyles} h-[110px] flex items-center`}
        onClick={onAchievementClick("achievements")}
      >
        Add a new Mission
      </li>
      <li
        className={`${sharedStyles} h-[110px] flex items-center`}
        onClick={onAchievementClick("idea")}
      >
        Share an Idea
      </li>
      <li
        className={`${sharedStyles} h-[110px] flex items-center`}
        onClick={onAchievementClick("cooptation")}
      >
        Invite a Talent
      </li>
      <li
        className={`${sharedStyles} h-[110px] flex items-center`}
        onClick={onAchievementClick("talentHub")}
      >
        create a Collab Space
      </li>
    </ul>
  </div>
);
