import React from "react";

const PercentageViz = ({ yesCount, noCount }) => {
  const total = yesCount + noCount;
  const yesPercentage = Math.round((yesCount / total) * 100) || 0;
  const noPercentage = Math.round((noCount / total) * 100) || 0;

  const generateBars = (count) => {
    return Array.from({ length: 30 }, (_, i) => {
      const threshold = Math.floor((count / total) * 30);
      return (
        <span
          key={i}
          className={`inline-block w-[2px] h-4 mx-[1px] ${
            i < threshold ? "bg-white" : "bg-white/30"
          }`}
        />
      );
    });
  };

  return (
    <div className="bg-black rounded-2xl p-6 text-white w-full mt-20 mb-10">
      <div className="space-y-4">
        <div className="flex items-center justify-between">
          <span className="text-xl font-mono">Yes</span>
          <div className="flex items-center gap-4">
            <div className="flex items-center">{generateBars(yesCount)}</div>
            <span className="text-xl font-mono w-16 text-right">
              {yesPercentage}%
            </span>
          </div>
        </div>

        <div className="flex items-center justify-between">
          <span className="text-xl font-mono">No</span>
          <div className="flex items-center gap-4">
            <div className="flex items-center">{generateBars(noCount)}</div>
            <span className="text-xl font-mono w-16 text-right">
              {noPercentage}%
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PercentageViz;
