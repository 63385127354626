import React from "react";
import Password from "../../../../images/icones/Password.svg";

const PasswordModal = ({ closeModal, setPasswordModal }) => {
  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-30"
      onClick={closeModal}
    >
      <div
        className="bg-black p-5 rounded-[20px] shadow-lg w-[700px] h-[500px]"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-end relative top-[10px] right-[10px]">
          <button
            className="font-silka-mono text-slate-300 text-[11px] rounded uppercase underline cursor-pointer hover:text-white transition-colors duration-300"
            onClick={()=> {
              closeModal()
              setPasswordModal(false)}
            }
          >
            close
          </button>
        </div>
        <p className="mb-10 mt-6  text-white text-center font-silka-mono font-semibold text-2xl">
          Temporary Password
        </p>
        <div className="relative flex justify-center text-center mb-5">
          <div className="w-[130px] h-[130px] rounded-lg flex items-center justify-center mt-6">
            <img className="w-full h-auto" src={Password} />
          </div>
        </div>
        <p className="mb-4 mt-10 text-white text-center font-silka-mono text-4xl">
          123456
        </p>
        <div className="flex justify-center items-center m-6">
          <p className="mb-4 mt-2 text-white text-center font-silka-mono">
            In conclusion, send Julien his temporary password using your usual
            method, and remind him to check his emails
          </p>
        </div>
      </div>
    </div>
  );
};

export default PasswordModal;
