import React from "react";

const TextAreaWithLabel = ({
  label,
  value,
  onChange,
  placeholder,
  bg,
  name,
  maxLength
}) => (
  <div className="mb-16">
    <label className="block font-silka-mono font-semibold transform translate-x-2">
      {label}
    </label>
    <textarea
      value={value}
      name={name}
      onChange={onChange}
      placeholder={placeholder}
      className={`w-full h-[8rem] p-2 ${bg} border-2 border-black rounded-md mt-1 outline-none resize-none placeholder-black`}
      maxLength={maxLength}
    />
  </div>
);

export default TextAreaWithLabel;
