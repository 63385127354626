import React, { useEffect, useState } from "react";
import Card from "./Card";
import personna from "../../../images/icones/personna.png";
import useCountdown from "../../../pages/ExecutionBoard/useCountdown";
import useStore from "../../../API/store";

const CardsList = ({
  dioData,
  isSplitView,
  selectedCardIndex,
  onCardClick,
  selectedStatus,
}) => {

  const { dioTasks,loading, combinedTasksAndIdeas } = useStore();

  const { hours, minutes, seconds } = useCountdown(dioTasks.remainingTime);

  const translationClasses = [
    "-translate-y-16",
    "translate-y-32",
    "translate-y-16",


  ];

  const mdTranslationClasses = ["translate-y-12", "translate-y-24"];

  const getTransformClass = (index, cols) => {
    if (cols === 1) {
      return "";
    } else if (cols === 2) {
      return mdTranslationClasses[index % mdTranslationClasses.length];
    } else {
      return translationClasses[index % translationClasses.length];
    }
  };

  const [columns, setColumns] = useState(1);

  useEffect(() => {
    const updateColumns = () => {
      const width = window.innerWidth;
      if (width >= 1240) {
        setColumns(3);
      } else if (width >= 780) {
        setColumns(2);
      } else {
        setColumns(1);
      }
    };

    updateColumns();

    window.addEventListener("resize", updateColumns);
    return () => window.removeEventListener("resize", updateColumns);
  }, []);

  combinedTasksAndIdeas.filter((execution) => {
    const userName = localStorage.getItem("userName");
    const userId = parseInt(localStorage.getItem("userId"));

    if (execution.status_ === "Draft idea") {
      if (execution.id_talent_creator === userId) {
        execution.actionText = "CONTINUE";
        return true;
      }
      return false;
    }

    if (
      execution.talent_name === userName &&
      execution.status_ === "On going"
    ) {
      execution.actionText = "ACHIEVE IT";
      return true;
    } else if (
      execution.status_ === "In review" &&
      execution.talent_name !== userName
    ) {
      execution.actionText = "MY FEEDBACK";
      return true;
    } else if (execution.status_ === "Open") {
      execution.actionText = "I WANT IT";
      return true;
    } else if (
      execution.talent_name === userName &&
      execution.status_ === "Achieved"
    ) {
      execution.actionText = "COLLECT";
      return true;
    } else if (
      execution.status_ === "On going idea"
    ) {
      if (execution.id_talent_creator && userId && execution.id_talent_creator === userId) {
        execution.displayStatus = "Achieved idea";
      } else {
        execution.displayStatus = "On going idea";
      }
      return true;
    }
    return false;
  });
  
  const filterByStatus = (card, status) => {
    const statusToCheck = card.displayStatus || card.status_;
    
    switch (status) {
      case "Achieved":
      case "Achieved idea":
        return (
          card.actionText === "COLLECT" &&
          card.talent_name === dioData[0]?.member.name &&
          (statusToCheck === status || statusToCheck === "Achieved idea")
        );
  
      case "Open":
      return  card.status_ === status
      case "In review":
      case "On going idea":

      return card.talent_name !== dioData[0]?.member.name && (statusToCheck === status || (card.talent_name !== dioData[0]?.member.name &&statusToCheck === "On going idea"));
      
  
      case "On going":
        return (
          card.talent_name === dioData[0]?.member.name &&
          statusToCheck === "On going" 
        );
  
      case "Draft idea":
        return (
          statusToCheck === "Draft idea" &&
          card.id_talent_creator === parseInt(localStorage.getItem("userId"))
        );
  
      default:
        return true; // Retourne toutes les cartes par défaut
    }
  };
  

  const filteredCardsData =
    selectedStatus && selectedStatus !== "all"
      ? combinedTasksAndIdeas.filter((card) => filterByStatus(card, selectedStatus))
      : combinedTasksAndIdeas;

  const sortedCardsData = filteredCardsData.sort((a, b) => {
    const dateA = new Date(a.creation_date);
    const dateB = new Date(b.creation_date);
    return dateB - dateA;
  });

  const getBgColor = (selectedStatus) => {
    switch (selectedStatus) {
      case "On going":
        return "bg-orange3";
      case "In review":
      case "On going idea":
        return "bg-aqua3";
      case "Open":
        return "bg-gray-100";
      case "Achieved":
        return "bg-gains3";
      case "In review idea":
        return "bg-aqua3";
      case "Open idea":
        return "bg-gray-100";
      case "Achieved idea":
        return "bg-gains3";
      case "Draft idea":
        return "bg-orange3";
      default:
        return "bg-black";
    }
  };
  if (loading) {
    return           <div className="flex items-center justify-center h-full">
    <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-black-500 border-opacity-75"/>
  </div>
  }
  return (
    <div
      className={`grid grid-cols-1 medium:grid-cols-2 large:grid-cols-3   gap-x- max-w-screen-2md  ${isSplitView ? "twoXl:gap-[4rem]  extraLarge:gap-[2rem] gap-[1rem]" : "twoXl:gap-[4rem] extraLarge:gap-[3rem]  gap-[2rem]"}`}
    >
      {sortedCardsData.map((execution, index) => (
        <div
          key={index}
          className={`transform ${getTransformClass(index, columns)} ${selectedCardIndex === execution.id ? "border-4 border-black rounded-[2rem]" : ""}`}
          onClick={() => onCardClick(execution.id, execution.actionText)}
        >
          <Card
            date={
              execution?.Expected_date
                ? `${execution?.Expected_date.slice(8, 10)}\n${execution?.Expected_date.slice(5, 7)}`
                : ""
            }
            title={execution.exec_description || ""}
            users ={execution.membre_first_name 
            ? `${execution.membre_first_name} ${execution.performor_name}` 
            : execution.performor_name}
            thanks={execution.score_thanks || "Soon"}
            actionText={execution.actionText}
            organisation={execution.do_name}
            bgColor={getBgColor(execution.displayStatus || execution.status_, execution)}
            isSplitView={isSplitView}
            profilePics={
              execution.profilePics ? execution.profilePics : [personna]
            }
            id={execution.id}
            status={execution.displayStatus || execution.status_}
            creation_date={execution.creation_date}
          />
        </div>
      ))}
    </div>
  );
};

export default CardsList;
