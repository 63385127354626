import React, { useState } from "react";
import Cookies from "js-cookie";
import Header from "../HomePage/Components/Header";
import logo from "../../images/thankward-logo-removebg.png";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL + "/login/api/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, password }),
        },
      );

      if (response.status === 200) {
        const data = await response.json();
        localStorage.setItem("userId", data.userId);
        localStorage.setItem("userName", data.userName);
        localStorage.setItem("firstName", data.firstName);
        localStorage.setItem("email", data.email);
        localStorage.setItem("do_ids", data.do_ids);
        localStorage.setItem("sponsored", data.sponsored);
        Cookies.set("token", data.token, { secure: true });

        // Rediriger en fonction de la première connexion
        if (data.first_connection === 0) {
          window.location.href = "/Homepage";
        } else {
          window.location.href = "/Welcome";
        }

        // Récupérer les données de l'utilisateur après la connexion réussie
        //const userId = data.userId; // Utilisez l'userId de la réponse
        //const userResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/DIO/user/${userId}`);

        // Assurez-vous que userResponse contient des données avant de les utiliser
        //if (userResponse.data) {
        //  localStorage.setItem("IdsDO", userResponse.data.id);
        //}
      } else {
        alert("Identifiant ou mot de passe incorrect.");
      }
    } catch (error) {
      console.error("Erreur lors de la connexion :", error);
    }
    console.log("Login attempt with:", { email, password });
  };

  return (
    <div className="min-h-screen bg-gray-100 flex">
      {/* Left Section */}
      <div className="w-1/2 bg-aqua3 p-12 flex flex-col justify-center">
        <h1 className="text-5xl font-silka-mono mb-8 ">
          A new world requires a new model
        </h1>

        <div className="mt-12">
          <h2 className="font-silka-mono mb-6">
            <span className="font-bold">Thankward</span> in three sentences:
          </h2>

          <div className="space-y-8">
            <div className="flex items-start">
              <span className="font-silka-mono font-bold mr-2">1-</span>
              <p className="font-silka-mono">
                A universal and systematic wealth-creation model that simplifies
                the journey toward a successful life for deserving individuals.
              </p>
            </div>

            <div className="flex items-start">
              <span className="font-silka-mono font-bold mr-2">2-</span>
              <p className="font-silka-mono">
                Values of recognition and trust that enable free and fair
                progress toward prosperity.
              </p>
            </div>

            <div className="flex items-start">
              <span className="font-silka-mono font-bold mr-2">3-</span>
              <p className="font-silka-mono">
                A cutting-edge, intuitive tech platform that makes the process
                actionable, enjoyable, and highly powerful.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Right Section */}
      <div className="w-1/2 bg-gray-50 p-12 flex flex-col justify-center">
        <div className="max-w-md mx-auto w-full">
          <h2 className="text-3xl font-silka-mono mb-8">Already member?</h2>

          <form onSubmit={handleLogin} className="space-y-6">
            <div>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="YOUR EMAIL"
                className="w-full p-3 border-2 border-black rounded-lg font-silka-mono focus:outline-none"
                required
              />
            </div>

            <div>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="YOUR PASSWORD"
                className="w-full p-3 border-2 border-black rounded-lg font-silka-mono focus:outline-none"
                required
              />
            </div>

            <button
              type="submit"
              className="w-[30%] bg-black text-white py-3 font-silka-mono hover:bg-gray-800 transition-colors rounded-full"
            >
              WELCOME
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
