import React, { useState, useEffect, useRef } from "react";
import TextAreaWithLabel from "../../../Components/TextAreaWithLabel";
import useStore from "../../../API/store";
import arrow from "../../../images/icones/arrow.png";
import ResourceLink from "../Components/ResourceLink";

const EditIdeaView = ({ handleGoBack, ideaData, dioData }) => {
  const {
    link,
    setLink,
    isInputVisible,
    setIsInputVisible,
    updateIdea,
    setLoading,
    setError
  } = useStore();
  
  const [title, setTitle] = useState(ideaData.exec_description || ideaData.title || "");
  const [describeIdea, setDescribeIdea] = useState(ideaData.comment_highlight || ideaData.description || "");
  const [importantIdea, setImportantIdea] = useState(ideaData.comment_improvement || ideaData.importance || "");
  const [date, setDate] = useState(() => {
    if (ideaData.expected_date) {
      const d = new Date(ideaData.expected_date);
      return d.toISOString().split('T')[0];
    }
    return null;
  });
  const [isCalendarOpen, setIsCalendarOpen] = useState(!!ideaData.expected_date);
  const [isEditingTitle, setIsEditingTitle] = useState(false);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };
  const handleLinkClick = () => setIsInputVisible(!isInputVisible);
  const handleInputChange = (event) => setLink(event.target.value);

  const handleCalendar = () => {
    setIsCalendarOpen(true);
  };

  const handleDateChange = (e) => {
    setDate(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Log pour déboguer
    console.log("Form values:", {
      title,
      describeIdea,
      importantIdea,
      date,
      id_do: dioData[0]?.id
    });

    // Vérification plus précise des champs
    if (!title?.trim()) {
      setError("Title is required");
      setLoading(false);
      return;
    }

    if (!describeIdea?.trim()) {
      setError("Description is required");
      setLoading(false);
      return;
    }

    if (!importantIdea?.trim()) {
      setError("Importance is required");
      setLoading(false);
      return;
    }

    if (!date) {
      setError("Date is required");
      setLoading(false);
      return;
    }

    if (!dioData?.[0]?.id) {
      setError("Organization ID is required");
      setLoading(false);
      return;
    }

    try {
      await updateIdea(
        ideaData.id,
        title,
        describeIdea,
        importantIdea,
        date,
        dioData[0].id,
        "On going idea"
      );

      handleGoBack();
    } catch (error) {
      console.error("Submit error:", error);
      setError(error.response?.data?.error || error.message);
    } finally {
      setLoading(false);
    }
  };

  const newIdeaViewRef = useRef();

  useEffect(() => {
    if (newIdeaViewRef.current) {
      newIdeaViewRef.current.scrollIntoView({
        block: "start",
        behavior: "instant",
      });
    }
  }, []);

  return (
    <div
      ref={newIdeaViewRef}
      className="w-1/2 bg-orange3 p-32 overflow-y-auto max-h-[calc(110vh-64px)]"
    >
      <div className="flex justify-between">
        <img
          src={arrow}
          alt="retour"
          className="w-12 h-12 cursor-pointer"
          onClick={handleGoBack}
        />
        {date ? (
          <input
            type="date"
            className="text-xs w-[132px] h-[60px] p-4 pl-3 pr-3 mb-6 flex items-center justify-center text-white bg-stone-950 rounded-lg font-silka-mono cursor-pointer"
            onChange={handleDateChange}
            value={date}
            min={new Date().toISOString().split("T")[0]}
          />
        ) : !isCalendarOpen ? (
          <div
            onClick={handleCalendar}
            className="text-xs w-[92px] h-[180px] p-4 pl-3 pr-3 mb-6 flex flex-col items-start justify-center text-white bg-stone-950 rounded-lg font-silka-mono cursor-pointer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6 mb-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.25 3v1.5m7.5-1.5v1.5M3.75 9h16.5M4.5 5.25h15a2.25 2.25 0 012.25 2.25v11.25a2.25 2.25 0 01-2.25 2.25H4.5a2.25 2.25 0 01-2.25-2.25V7.5a2.25 2.25 0 012.25-2.25z"
              />
            </svg>
            <p>
              When would <br /> you like <br /> to see the idea <br /> come to
              life?
            </p>
          </div>
        ) : (
          <input
            type="date"
            className="text-xs w-[132px] h-[60px] p-4 pl-3 pr-3 mb-6 flex items-center justify-center text-white bg-stone-950 rounded-lg font-silka-mono cursor-pointer"
            onChange={handleDateChange}
            value={date}
            min={new Date().toISOString().split("T")[0]}
          />
        )}
      </div>
      <form onSubmit={handleSubmit} className="mt-10">
      {!isEditingTitle ? (
        <textarea
          value={title}
          onChange={handleTitleChange}
          onBlur={() => setIsEditingTitle(false)}
          placeholder={`Title
of your idea`}
          maxLength={40}
          autoFocus
          className="font-silka-mono font-semibold text-4xl underline italic mt-6 bg-transparent border-none outline-none resize-none overflow-hidden h-18 placeholder-black"
          rows={2}
        />
      ) : (
        <div className="font-silka-mono font-semibold text-4xl mt-6 underline italic cursor-pointer">
          {title.split("\n").map((line, index) => (
            <span key={index}>
              {line}
              {index < title.split("\n").length - 1 && <br />}
            </span>
          ))}
        </div>
      )}


        <TextAreaWithLabel
          label={<>Describe your idea</>}
          value={describeIdea}
          placeholder="Provide a brief description"
          onChange={(e) => setDescribeIdea(e.target.value)}
          bg="bg-orange3"
        />
        <TextAreaWithLabel
          label={<>Why your idea is important?</>}
          value={importantIdea}
          placeholder="Provide a brief description"
          onChange={(e) => setImportantIdea(e.target.value)}
          bg="bg-orange3"
        />

        <ResourceLink
          handleLinkClick={handleLinkClick}
          isInputVisible={isInputVisible}
          link={link}
          handleInputChange={handleInputChange}
        />

        <div className="flex flex-col space-y-2">
          <div className="flex flex-row items-center space-x-4 mt-10">
            <button
              type="submit"
              className="w-[15em] px-8 py-4 bg-black text-white rounded-[50px] font-silka-mono uppercase"
            >
              share your idea
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditIdeaView; 