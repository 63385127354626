import React from "react";


import ExecutionBoard from "./ExecutionBoard";




const Archives = () => {
  return ( <ExecutionBoard page="archives" />);
}

export default Archives;