import React from "react";
import lightbulb from "../../../images/lightbulb.png";
import t100h from "../../../images/t100h.png";

const IdeaModal = ({
  onClose,
  setOpenNewIdeaView,
  setIsSplitView,
  setCreationType,
  setIsCreating,
}) => {
  const handleClick = () => {
    setOpenNewIdeaView(true);
    setIsSplitView(true);
    setCreationType("idea");
    setIsCreating(true);
    onClose();
  };

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-30"
      onClick={onClose}
    >
      <div
        className="bg-black p-5 rounded-[20px] shadow-lg w-[700px] h-[500px]"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-end relative top-[10px] right-[10px]">
          <button
            className="font-silka-mono text-slate-300 text-[11px] rounded uppercase underline cursor-pointer hover:text-white transition-colors duration-300"
            onClick={onClose}
          >
            Next time
          </button>
        </div>
        <p className="mb-4 mt-10 text-white text-center font-silka-mono font-semibold text-2xl">
          Do you have a good idea? <br /> Share it with your community
        </p>
        <div className="relative flex justify-center text-center mb-5">
          <img
            src={lightbulb}
            alt="Lightbulb"
            className="w-[130px] h-[130px]"
          />
          <img
            src={t100h}
            alt="t100h"
            className="w-[90px] h-[35px] absolute right-[160px] top-[50px]"
          />
        </div>
        <div className="flex justify-center items-center m-6">
          <p className="mb-4 mt-2 text-white text-center font-silka-mono">
            Share a good idea!
          </p>
        </div>
        <div className="flex justify-center mb-4 mt-2 text-white text-center font-silka-mono">
          <button
            onClick={handleClick}
            className="border border-white text-white px-5 py-2.5 rounded-full bg-transparent cursor-pointer transition-colors duration-300 ease-in-out hover:bg-white hover:text-black"
          >
            SHARE
          </button>
        </div>
      </div>
    </div>
  );
};

export default IdeaModal;
