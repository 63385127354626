// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.better{
    margin-top: 10%;
    font-size: 150%; 
    font-weight: 500;   
}

h3{
  font-size: 150%;  
}


.input-feed{
    background-color: rgb(221, 216, 216);
    color: black;
    height: 10vh;
    width: 30vw;
    border-radius: 10px;
    border: 200%;
    margin-left: 10vw;
    margin-right: 10vw;
    margin-top: 2%;
}
.feedback{
    margin-top: 10%;
    font-size: 100%;
    border-radius: 2vh;
    background-color:  white;
    width: 25%;
    height: 20%;
    justify-content: center;
    align-items: center;
    color: black;
    margin-left: 0%;
    border-radius: 20px;
    font-family: "Kumbh Sans";
    padding: 5%;
    display: flex;
    border: 1px solid transparent;
    cursor: pointer;
}
.feedback:hover{
  background-color:  gray;
  width: 10vw;
  height: 3vh;
}

.input-feed {
    border: none; 
    color: black; 
    font-family: 'Kumbh Sans', sans-serif;
  }

  ::placeholder {
    color: black;
  }

  .input-feed::-webkit-input-placeholder {
    color: black;
  }
  
  .input-feed::-moz-placeholder {
    color: black;
  }
  
  .input-feed:-ms-input-placeholder {
    color: black;
  }`, "",{"version":3,"sources":["webpack://./src/pages/Review/PeerReview/PeerReviewNotYet.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,eAAe;IACf,gBAAgB;AACpB;;AAEA;EACE,eAAe;AACjB;;;AAGA;IACI,oCAAoC;IACpC,YAAY;IACZ,YAAY;IACZ,WAAW;IACX,mBAAmB;IACnB,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,cAAc;AAClB;AACA;IACI,eAAe;IACf,eAAe;IACf,kBAAkB;IAClB,wBAAwB;IACxB,UAAU;IACV,WAAW;IACX,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,eAAe;IACf,mBAAmB;IACnB,yBAAyB;IACzB,WAAW;IACX,aAAa;IACb,6BAA6B;IAC7B,eAAe;AACnB;AACA;EACE,uBAAuB;EACvB,WAAW;EACX,WAAW;AACb;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,qCAAqC;EACvC;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,YAAY;EACd","sourcesContent":[".better{\n    margin-top: 10%;\n    font-size: 150%; \n    font-weight: 500;   \n}\n\nh3{\n  font-size: 150%;  \n}\n\n\n.input-feed{\n    background-color: rgb(221, 216, 216);\n    color: black;\n    height: 10vh;\n    width: 30vw;\n    border-radius: 10px;\n    border: 200%;\n    margin-left: 10vw;\n    margin-right: 10vw;\n    margin-top: 2%;\n}\n.feedback{\n    margin-top: 10%;\n    font-size: 100%;\n    border-radius: 2vh;\n    background-color:  white;\n    width: 25%;\n    height: 20%;\n    justify-content: center;\n    align-items: center;\n    color: black;\n    margin-left: 0%;\n    border-radius: 20px;\n    font-family: \"Kumbh Sans\";\n    padding: 5%;\n    display: flex;\n    border: 1px solid transparent;\n    cursor: pointer;\n}\n.feedback:hover{\n  background-color:  gray;\n  width: 10vw;\n  height: 3vh;\n}\n\n.input-feed {\n    border: none; \n    color: black; \n    font-family: 'Kumbh Sans', sans-serif;\n  }\n\n  ::placeholder {\n    color: black;\n  }\n\n  .input-feed::-webkit-input-placeholder {\n    color: black;\n  }\n  \n  .input-feed::-moz-placeholder {\n    color: black;\n  }\n  \n  .input-feed:-ms-input-placeholder {\n    color: black;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
