import React, { useState, useEffect } from "react";

const HourglassIcon = ({ isSplitView }) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width={isSplitView ? "14" : "24"} 
    height={isSplitView ? "14" : "24"} 
    viewBox="0 0 24 24" 
    stroke="currentColor" 
    strokeWidth="1"
    strokeLinecap="round" 
    strokeLinejoin="round"
  >
    {/* Forme du sablier avec remplissage */}
    <path 
      d="M5 2h14v4l-7 6 7 6v4H5v-4l7-6-7-6V2z" 
      fill="currentColor"
    />
  </svg>
);

const IdeaCountdown = ({ creationDate, isSplitView, displayMode = "full" }) => {
  const [timeLeft, setTimeLeft] = useState("");
  const [isExpired, setIsExpired] = useState(false);
  
  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date();
      const creation = new Date(creationDate);
      const deadline = new Date(creation.getTime() + (7 * 24 * 60 * 60 * 1000));
      const difference = deadline - now;
      
      if (difference <= 0) {
        setIsExpired(true);
        if (isSplitView && displayMode === "compact") {
          return (
            <div className="w-6 h-6 flex items-center justify-center">
              <HourglassIcon isSplitView={isSplitView} />
            </div>
          );
        }
        if (displayMode === "compact") {
          return <HourglassIcon isSplitView={isSplitView} />;
        }
        return (
          <div className="flex items-center gap-2">
            <span>Feedback and voting time has expired</span>
            <HourglassIcon isSplitView={isSplitView} />
          </div>
        );
      }
      
      setIsExpired(false);
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);
      
      const formatNumber = (num) => num < 10 ? `0${num}` : num;
      
      if (displayMode === "compact") {
        if (days > 0) {
          return (
            <>
              <span className="block font-silka-mono">{days}</span>
              <span className="block font-silka-mono">D</span>
            </>
          );
        } else if (hours > 0) {
          return (
            <>
              <span className="block font-silka-mono">{formatNumber(hours)}</span>
              <span className="block font-silka-mono">H</span>
            </>
          );
        } else if (minutes > 0) {
          return (
            <>
              <span className="block font-silka-mono">{formatNumber(minutes)}</span>
              <span className="block font-silka-mono">M</span>
            </>
          );
        } else {
          return (
            <>
              <span className="block font-silka-mono">{formatNumber(seconds)}</span>
              <span className="block font-silka-mono">S</span>
            </>
          );
        }
      } else {
        return `${days}D ${formatNumber(hours)}:${formatNumber(minutes)}:${formatNumber(seconds)}`;
      }
    };
    
    setTimeLeft(calculateTimeLeft());
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    
    return () => clearInterval(timer);
  }, [creationDate, displayMode, isSplitView]);
  
  return (
    <div className={`
      ${isSplitView && displayMode === "compact" 
        ? "text-[10px] h-[38px] w-[22px] p-1 pt-2 pb-2"
        : displayMode === "compact"
        ? "text-sm h-[50px] w-[32px] p-1 pl-3 pr-3" 
        : "text-sm h-[52px] w-auto opacity-50 mt-20 mb-20"
      }
      font-silka-mono bg-black rounded-lg text-white
      flex flex-col items-center justify-center
    `}>
      {timeLeft}
    </div>
  );
};

export default IdeaCountdown; 