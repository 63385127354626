import React, { useEffect, useRef } from "react";
import TextAreaWithLabel from "../../../Components/TextAreaWithLabel";

import arrow from "../../../images/icones/arrow.png";
import idea from "../../../images/icones/Idea.png";
import profil from "../../../images/icones/personna.png";
import IdeaCountdown from "../../../Components/IdeaCountdown.js";
import useStore from "../../../API/store";


const IdeaFeedbackView = ({ handleGoBack, selectedCardDetails, dioData, setSelectedStatus }) => {
    const {
        feelingIdea, 
        setFeelingIdea,
        contribution, 
        setContribution,
        vote,
        setVote,
        sendFeedbackIdea,
        setLoading,
        setError,
        fetchIdeas
      } = useStore();
      const completName = `${selectedCardDetails.membre_first_name || 'Prénom inconnu'} ${selectedCardDetails.performor_name || 'Nom inconnu'}`;
      const usersList = [completName];
  const profilePics = [profil];
  const ideaViewRef = useRef();

  useEffect(() => {
    if (ideaViewRef.current) {
      ideaViewRef.current.scrollIntoView({
        block: "start",
        behavior: "instant",
      });
    }
  }, []);
  const validateForm = () => {
    return (
      feelingIdea.trim() !== "" && // Vérifie que "feelingIdea" n'est pas vide
      contribution.trim() !== ""  // Vérifie que "contribution" n'est pas vide
    );
  };
  
  const userId = localStorage.getItem("userId");
  const idContributor = localStorage.getItem("userId")
  const idIdea = selectedCardDetails.id
  const onSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      alert("Please fill all required fields.");
      return;
    }
    setLoading(true);
    sendFeedbackIdea(
        Number(idIdea), 
        feelingIdea,
        contribution, 
        Number(idContributor), 
        Number(vote),
    )
    .then(() => {
      console.log("IDEA successfully submitted!");
      fetchIdeas(dioData).then(() => {
        console.log("IDEA successfully submitted!");
        setLoading(false);
      });
      setFeelingIdea("");
      setContribution("")
      handleGoBack();
      setSelectedStatus("all");
    })
    .catch((error) => {
      const errorMessage = error.response
        ? error.response.data.message
        : error.message || "Unknown error";
      setError(errorMessage, "<======Error adding IDEA.");
      setLoading(false);
    });
    console.log({
        feelingIdea,        contribution, 
        vote,
        idIdea, 
        idContributor, 
    });
  };

  // Trouver le nom de la collab space correspondant à l'idée
  const collabSpaceName = dioData?.find(dio => dio.id === selectedCardDetails.id_do)?.name || "Collab Space";

  return (
    <div
      ref={ideaViewRef}
      className="w-1/2 bg-aqua3 p-32 overflow-y-auto max-h-[calc(110vh-64px)]"
    >
      <div className="flex justify-between">
        <img
          src={arrow}
          alt="retour"
          className="w-12 h-12 cursor-pointer"
          onClick={handleGoBack}
        />
        <div className=" text-xs w-[40px] h-[60px] p-2 mb-6 flex items-center justify-center text-white bg-stone-950 rounded-lg font-silka-mono">
          <img src={idea} alt="idée" className="w-full h-auto" />
        </div>
      </div>
      <p className="font-silka-mono mt-10">{collabSpaceName}</p>
      <p className="font-silka-mono font-semibold text-4xl mt-6">
        {selectedCardDetails?.exec_description}
      </p>

      <div className="flex items-center space-x-2 mt-10">
        {usersList.map((user, index) => (
          <React.Fragment key={index}>
            {profilePics[index] && (
              <img
                src={profilePics[index]}
                alt={user}
                className="w-8 h-8 rounded-full"
              />
            )}
            <p className="text-sm font-silka-mono">{user}</p>
            {index < usersList.length - 1 && <span> &gt; </span>}
          </React.Fragment>
        ))}
      </div>

      <div className="flex justify-center items-center w-[100px] h-12 border-2 border-black mt-20 rounded-2xl transform -translate-x-4">
        <p className="font-silka-mono font-bold uppercase text-center p-2">
          picth
        </p>
      </div>

      <p className="font-silka-mono mt-10">
  {selectedCardDetails.comment_highlight}
      </p>
      <p className="font-silka-mono mt-5">
  {selectedCardDetails.comment_improvement}
      </p>


{  Number(selectedCardDetails.id_talent_creator) !== Number(userId)  &&
    <form onSubmit={onSubmit} className="mt-10">
        <TextAreaWithLabel
          label={<>My feeling about this idea</>}
          value={feelingIdea}
          placeholder="Write"
          onChange={(e) => setFeelingIdea(e.target.value)}
          bg="bg-aqua3"
        />
        <TextAreaWithLabel
        label={<>How can I contribute and when?</>}
        value={contribution || ""} // Assure que value n'est jamais undefined ou null
        placeholder="Write"
        onChange={(e) => setContribution(e.target.value)}
        bg="bg-aqua3"
        />
        <IdeaCountdown creationDate={selectedCardDetails.creation_date} />
            <div className="flex flex-row space-x-4 justify-start mt-20">
            <button
                type="submit"
                className="w-[16em] h-10 px-6 py-2 bg-black text-white rounded-[50px] font-silka-mono uppercase text-xs"
                onClick={(e) => setVote(1)}
            >
                YES LET'S DO IT
            </button>
            <button
                type="submit"
                className="w-[18em] h-10 px-6 py-2 bg-aqua3 border-2 border-black text-black rounded-[50px] font-silka-mono uppercase text-xs"
                onClick={(e) => setVote(0)}
            >
                NO thanks!
            </button>
            </div>
      
      </form>  }

    </div>
  );
};

export default IdeaFeedbackView