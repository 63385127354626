import React, { useEffect, useRef } from "react";
import arrow from "../../../images/icones/arrow.png";
import folder from "../../../images/icones/folder.png";

import profil from "../../../images/icones/personna.png";

const OportunityView = ({ handleGoBack, cardDetails, dioData }) => {
  const usersList = [cardDetails.talent_name, cardDetails.performor_name];
  const profilePics = [profil, profil];

  const day = cardDetails.Expected_date?.slice(8, 10);
  const month = cardDetails.Expected_date?.slice(5, 7);

  const opportunityViewRef = useRef();

  useEffect(() => {
    if (opportunityViewRef.current) {
      opportunityViewRef.current.scrollIntoView({
        block: "start",
        behavior: "instant",
      });
    }
  }, []);
  return (
    <div
      ref={opportunityViewRef}
      className="w-1/2 bg-rightGreyBg p-32 overflow-y-auto max-h-[calc(110vh-64px)]"
    >
      <div className="flex justify-between">
        <img
          src={arrow}
          alt="retour"
          className="w-12 h-12 cursor-pointer"
          onClick={handleGoBack}
        />
        {cardDetails.Expected_date && (
          <div className=" text-xs w-[32px] h-[50px] p-1 pl-3 pr-3 mb-6 flex items-center justify-center text-white bg-stone-950 rounded-lg font-silka-mono">
            {day} <br />
            {month}
          </div>
        )}
      </div>
      <p className="font-silka-mono mt-10">Thankward by MKIF</p>
      <p className="font-silka-mono font-semibold text-3xl mt-6">
        {cardDetails?.exec_description}
      </p>

      <div className="flex items-center space-x-2 mt-10">
        {usersList.map((user, index) => (
          <React.Fragment key={index}>
            {profilePics[index] && (
              <img
                src={profilePics[index]}
                alt={user}
                className="w-8 h-8 rounded-full"
              />
            )}
            <p className="text-sm font-silka-mono">{user}</p>
            {index < usersList.length - 1 && <span> &gt; </span>}
          </React.Fragment>
        ))}
      </div>

      {cardDetails.Instructions && (
        <div className="flex justify-center items-center w-[100px] h-12 border-2 border-black mt-20 rounded-2xl transform -translate-x-4">
          <p className="font-silka-mono font-bold uppercase text-center p-2">
            brief
          </p>
        </div>
      )}
      <p className="font-silka-mono mt-10">{cardDetails.Instructions}</p>
      {cardDetails.documentation_link && (
        <a
          href={cardDetails.documentation_link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="cursor-pointer mt-10 -translate-x-8"
            src={folder}
            alt="folder"
          />
        </a>
      )}

      <button
        type="submit"
        className="text-black font-silka-mono uppercase underline transform -translate-x-2 mt-10"
      >
        I want it
      </button>
    </div>
  );
};

export default OportunityView;
