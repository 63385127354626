import React, { useEffect, useRef } from "react";

import TextAreaWithLabel from "../../../Components/TextAreaWithLabel";

import useRadioQuestion from "../../../Components/Hooks/useRadioQuestion";
import RadioQuestion from "../../../Components/RadioQuestion";

import arrow from "../../../images/icones/arrow.png";
import folder from "../../../images/icones/Group 8795.png";
import useStore from "../../../API/store";

import profil from "../../../images/icones/personna.png";

const FeedbackView = ({ handleGoBack, selectedCardDetails, userName, setSelectedStatus }) => {
  const teamValueOptions = [

    { value: "Minimal", description: "Little to no perceived value", score: 1 },

    {

      value: "Moderate",

      description: "Some perceived value",

      score: 2,

    },

    {

      value: "Significant",

      description: "Noticeable value",

      score: 3,

    },

    {

      value: "Exceptional",

      description: "High perceived value",

      score: 4,

    },

  ];

 

  const resultQualityOptions = [

    { value: "Not Yet", description: "Needs improvement", score: 1 },

    { value: "Basic", description: "Minimum requirements met", score: 2 },

    {

      value: "Good",

      description: "All requirements met",

      score: 3,

    },

    { value: "High", description: "Exceeds requirements precisely", score: 4 },

    {

      value: "Outstanding",

      description: "Far exceeds expectations",

      score: 5,

    },

  ];

 

  const reactivityOptions = [

    { value: "Lacking", description: "needs improvement", score: 1 },

    { value: "Responsive", description: "Basic needs met", score: 2 },

    {

      value: "Proactive",

      description: "Prompt and timely",

      score: 3,

    },

    { value: "Agile", description: "Quick and adaptive", score: 4 },

    {

      value: "Exceptional",

      description: "Instant, outstanding response",

      score: 5,

    },
  ];
  const validateForm = () => {
    return (
      positiveAspect.trim() !== "" && // Vérifie que le champ positiveAspect n'est pas vide
      improvementSuggestion.trim() !== "" && // Vérifie que le champ improvementSuggestion n'est pas vide
      teamValue.selectedScore !== null && // Vérifie qu'une option a été sélectionnée pour teamValue
      resultQuality.selectedScore !== null && // Vérifie qu'une option a été sélectionnée pour resultQuality
      reactivity.selectedScore !== null // Vérifie qu'une option a été sélectionnée pour reactivity
    );
  };
  
  const {
    setPositiveAspect,
    positiveAspect,
    improvementSuggestion,
    setImprovementSuggestion,
    submitFeedback,
    setLoading,
    fetchExecutions,
    setError,
    dioData
  } = useStore();
  
  const usersList = [selectedCardDetails.talent_name, selectedCardDetails.performor_name];
  const profilePics = [profil, profil];
  const executionId = selectedCardDetails.id

  const onSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      alert("Please fill all required fields.");
      return;
    }
    const feedback = {
      positiveAspect,
      improvementSuggestion,
      teamValueScore: teamValue.selectedScore,
      resultQualityScore: resultQuality.selectedScore,
      reactivityScore: reactivity.selectedScore,
      executionId, 
    }; 
    setLoading(true)
    submitFeedback(feedback)
    .then(() => {
      console.log("Achievement successfully submitted!");
      fetchExecutions(dioData).then(() => {
        console.log("Achievement successfully submitted!");
        setLoading(false);
      });
      setPositiveAspect("");
      setImprovementSuggestion("");
      handleGoBack();
      setSelectedStatus("all");
    })
    .catch((error) => {
      setError(error, "<======Error adding achievement.");
      setLoading(false); // Stop loading in case of error
    });
};

  const teamValue = useRadioQuestion(teamValueOptions)
  const resultQuality = useRadioQuestion(resultQualityOptions)
  const reactivity = useRadioQuestion(reactivityOptions)


  const day = selectedCardDetails.creation_date.slice(8, 10);
  const month = selectedCardDetails.creation_date.slice(5, 7);

  const feddbackViewRef = useRef();

  useEffect(() => {
    if (feddbackViewRef.current) {
      feddbackViewRef.current.scrollIntoView({
        block: "start",
        behavior: "instant",
      });
    }
  }, []);


  return (
    <div
      ref={feddbackViewRef}
      className="w-1/2 bg-aqua3 p-32 overflow-y-auto max-h-screen"
    >
      <div className="flex justify-between">
        <img
          src={arrow}
          alt="retour"
          className="w-12 h-12 cursor-pointer"
          onClick={handleGoBack}
        />
        {selectedCardDetails.Expected_date && (
          <div className=" text-xs w-[32px] h-[50px] p-1 pl-3 pr-3 mb-6 flex items-center justify-center text-white bg-stone-950 rounded-lg font-silka-mono">
            {day} <br />
            {month}
          </div>
        )}
      </div>
      <p className="font-silka-mono mt-10">Thankward by MKIF</p>
      <p className="font-silka-mono font-semibold text-3xl mt-6">
        {selectedCardDetails?.exec_description}
      </p>

      <div className="flex items-center space-x-2 mt-10">
        {usersList.map((user, index) => (
          <React.Fragment key={index}>
            {profilePics[index] && (
              <img
                src={profilePics[index]}
                alt={user}
                className="w-8 h-8 rounded-full"
              />
            )}
            <p className="text-sm font-silka-mono">{user}</p>
            {index < usersList.length - 1 && <span> &gt; </span>}
          </React.Fragment>
        ))}
      </div>

      {selectedCardDetails.Instructions && (
        <div className="flex justify-center items-center w-[100px] h-12 border-2 border-black mt-20 rounded-2xl transform -translate-x-4">
          <p className="font-silka-mono font-bold uppercase text-center p-2">
            brief
          </p>
        </div>
      )}
      <p className="font-silka-mono mt-10">{selectedCardDetails.Instructions}</p>
      {selectedCardDetails.input_link && (
        <a
          href={selectedCardDetails.input_link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="cursor-pointer mt-10 bg-aqua3 -translate-x-8"
            src={folder}
            alt="folder"
          />
        </a>
      )}

      {selectedCardDetails.review_comments && (
        <div className="flex justify-center items-center w-[170px] h-12 border-2 border-black mt-20 rounded-2xl transform -translate-x-4">
          <p className="font-silka-mono font-bold uppercase text-center p-2">
            Liverables
          </p>
        </div>
      )}
      <p className="font-silka-mono mt-10">{selectedCardDetails.review_comments}</p>

      {selectedCardDetails.documentation_link && (
        <a
          href={selectedCardDetails.documentation_link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="cursor-pointer mt-10 bg-aqua3 -translate-x-8"
            src={folder}
            alt="folder"
          />
        </a>
      )}

      {selectedCardDetails?.status_ === "In review" &&
        selectedCardDetails?.talent_name !== userName &&
      <>
      <p className="font-silka-mono font-semibold text-4xl mt-14">
        Write <br /> your feedback
      </p>

      <form onSubmit={onSubmit} className="mt-10">
        <TextAreaWithLabel
          label="What aspects of this achievement do you want to highlight, and why are they important?"
          value={positiveAspect}
          placeholder="Write"
          onChange={(e) => setPositiveAspect(e.target.value)}
          bg="bg-aqua3"
        />

        <TextAreaWithLabel
          label="What could you suggest to improve it next time?"
          value={improvementSuggestion}
          placeholder="Write"
          onChange={(e) => setImprovementSuggestion(e.target.value)}
          bg="bg-aqua3"
        />

        <RadioQuestion
          label={
            <>
         In your teammate’s opinion, <br/>thow would he value <br/>of this mission be perceived?
            </>
          }
          options={teamValueOptions}
          selectedIndex={teamValue.selectedIndex}
          selectedValue={teamValue.selectedValue}
          selectedScore={teamValue.selectedScore}
          onChange={teamValue.handleChange}
        />
        <RadioQuestion
          label={
            <>
How would you <br/>rate the quality of <br/>this achievement?
            </>
          }
          options={resultQualityOptions}
          selectedIndex={resultQuality.selectedIndex}
          selectedValue={resultQuality.selectedValue}
          selectedScore={resultQuality.selectedScore}
          onChange={resultQuality.handleChange}
        />
        <RadioQuestion
          label={
            <>
         How would you <br/> rate the achiever’s  <br/>overall reactivity?
            </>
          }
          options={reactivityOptions}
          selectedIndex={reactivity.selectedIndex}
          selectedValue={reactivity.selectedValue}
          selectedScore={reactivity.selectedScore}
          onChange={reactivity.handleChange}
        />

        <div className="flex flex-col space-y-2">
          <div className="flex flex-row items-center space-x-4 mt-10">
            <button
              type="submit"
              className="w-[14em] px-8 py-4 bg-black text-white rounded-[50px] font-silka-mono uppercase "
            >
              publish
            </button>
          </div>
          {/* <button
            type="button"
            className="w-[14em] px-8 py-4 bg-aqua3 border-2 border-black text-black rounded-[50px] font-silka-mono uppercase"
          >
            Save for later
          </button> */}
        </div>
      </form>
      </>}
    </div>
  );
};

export default FeedbackView;
