import React, { useState, useEffect } from "react";

const Confetti = ({ isActive }) => {
  const [particles, setParticles] = useState([]);

  const colors = [
    "aqua",
    "orange",
    "softGreen",
    "gains",
    "#FFFFF",
    "#FF6600",
    "#6600CC",
    "#FF1493",
  ];

  const createParticle = () => ({
    id: Math.random(),
    x: Math.random() * (window.innerWidth * 0.8) - window.innerWidth * 0.2,
    // Position de départ plus haute
    y: -100,
    // Augmentation de la taille min/max des confettis
    size: Math.random() * (20 - 10) + 10,
    color: colors[Math.floor(Math.random() * colors.length)],
    speedX: Math.random() * 8 - 4,
    // Vitesse légèrement réduite pour compenser la position plus haute
    speedY: Math.random() * 2 + 1.5,
    rotation: Math.random() * 360,
    rotationSpeed: Math.random() * 10 - 5,
  });

  useEffect(() => {
    if (isActive) {
      // Augmentation du nombre de particules
      const newParticles = Array.from({ length: 240 }, createParticle);
      setParticles(newParticles);
    } else {
      setParticles([]);
    }
  }, [isActive]);

  useEffect(() => {
    if (particles.length > 0) {
      const animationFrame = requestAnimationFrame(() => {
        setParticles((prevParticles) =>
          prevParticles
            .map((particle) => ({
              ...particle,
              y: particle.y + particle.speedY,
              x: particle.x + particle.speedX,
              rotation: particle.rotation + particle.rotationSpeed,
              speedY: particle.speedY + 0.1,
              speedX: particle.speedX * 0.99,
            }))
            .filter((particle) => particle.y < window.innerHeight + 100),
        );
      });

      return () => cancelAnimationFrame(animationFrame);
    }
  }, [particles]);

  return (
    <div
      className="fixed inset-0 w-[320px] h-screen overflow-hidden pointer-events-none z-50"
      style={{
        transform: "translateX(-40%) translateY(-10%)",
        height: "100vh", // Augmentation de la hauteur du conteneur
      }}
    >
      {particles.map((particle) => (
        <div
          key={particle.id}
          className="absolute"
          style={{
            left: `${particle.x}px`,
            top: `${particle.y}px`,
            width: `${particle.size}px`,
            height: `${particle.size}px`,
            backgroundColor: particle.color,
            transform: `rotate(${particle.rotation}deg) scale(${Math.random() * 0.7 + 0.8})`, // Scale augmenté
            clipPath:
              "polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)",
            opacity: 0.8,
            boxShadow: `0 0 10px ${particle.color}`,
            transition: "none",
            zIndex: 9999,
          }}
        />
      ))}
    </div>
  );
};

export default Confetti;
