import { create } from "zustand";
import axios from "axios";
import Cookies from "js-cookie";

const useStore = create((set, get) => ({
  dioData: [],
  dioTasks: [],
  ideas: [],
  combinedTasksAndIdeas: [],
  loading: false,
  error: null,
  title: "",
  positiveAspect: "",
  selectedTalent: "",
  selectedOrganization: "Choose a Collab Space",
  selectedOrganizationId: 1,
  link: "",
  teamValue: { selectedScore: 0 }, // Initialisation avec un score par défaut
  complexity: { selectedScore: 0 },
  frequency: { selectedScore: 0 },
  isInputVisible: false,
  isEditingTitle: false,
  isCalendarOpen: false,
  date: new Date().toISOString().split("T")[0],
  menuOpenTalent: false, // Menu Talent par défaut
  menuOpenHub: false, // Menu Hub par défaut
  departHours: 24,
  improvementSuggestion: "",
  feelingIdea:"",
  vote:0,
  contribution:"",
  idDOcreated:"",
  talentsHubData: null,
  nameDO:"",
  pitch: "",
  whatToDo: "",
  whyImportant: "",
  organizationType: "",
  showResourceInput: false,
  clicked:true,
  filter:false,
  onGoingCount:0,
  openCount:0,
  inReviewCount:0,
  achievedCount:0,

  token: Cookies.get("token"), // Default value for token
  
  
  axiosInstance: axios.create({
    timeout: 60000, // Timeout of 60 seconds
  }),

  // Setters for the new achievement state
  setTitle: (title) => set({ title }),
  setPositiveAspect: (positiveAspect) => set({ positiveAspect }),
  setSelectedTalent: (selectedTalent) => set({ selectedTalent }),
  setSelectedOrganization: (selectedOrganization) =>
    set({ selectedOrganization }),
  setSelectedOrganizationId: (selectedOrganizationId) =>
    set({ selectedOrganizationId }),
  setLink: (link) => set({ link }),
  setTeamValue: (score) => set({ teamValue: { selectedScore: score } }),
  setComplexity: (score) => set({ complexity: { selectedScore: score } }),
  setFrequency: (score) => set({ frequency: { selectedScore: score } }),
  setIsInputVisible: (isInputVisible) => set({ isInputVisible }),
  setIsEditingTitle: (isEditingTitle) => set({ isEditingTitle }),
  setIsCalendarOpen: (isCalendarOpen) => set({ isCalendarOpen }),
  setDate: (date) => set({ date }),
  setMenuOpenTalent: (isOpen) => set({ menuOpenTalent: isOpen }),
  setMenuOpenHub: (isOpen) => set({ menuOpenHub: isOpen }),
  setDepartHours: (n) => set({ departHours: n }),
  setImprovementSuggestion : (improvementSuggestion) => set({improvementSuggestion}),
  setIdeas: (ideas) => set({ ideas }),
  setFeelingIdea:(feelingIdea)=> set({feelingIdea}),
  setVote:(vote)=> set({vote}),
  setContribution: (value) => set({ contribution: value }),
  setIdDOcreated: (idDOcreated)=> set({idDOcreated}),
  setNameDO:(nameDO)=> set({nameDO}),
  setPitch: (pitch)=> set({pitch}),
  setWhatToDo: (whatToDo)=> set({whatToDo}),
  setWhyImportant: (whyImportant)=> set({whyImportant}),
  setOrganizationType: (organizationType)=> set({organizationType}),
  setShowResourceInput:(showResourceInput)=> set({showResourceInput}),
  setClicked:(clicked)=>set({clicked}),
  setFilter:(filter)=>set({filter}),
  setOnGoingCount:(onGoingCount) =>set({onGoingCount}),
  setOpenCount:(openCount) =>set ({openCount}),
  setInReviewCount:(inReviewCount)=>set({inReviewCount}),
  setAchievedCount:(achievedCount)=>set({achievedCount}),

  // Setter for Dio Data
  setDioData: (data) => set({ dioData: data }),

  // Setter for Dio Tasks
  setDIOTasks: (tasks) => set({ dioTasks: tasks }),

  // Setter for loading state
  setLoading: (loading) => set({ loading }),

  // Setter for error state
  setError: (error) => set({ error }),

  fetchDioData: async () => {
    set({ loading: true, error: null });
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/DIO/data`,
      );
      set({ dioData: response.data });
      const user = response.data[0]?.member.first_name || "";
      set({ selectedTalent: user });
    } catch (error) {
      set({ error: "Error fetching Dio data." });
    } finally {
      set({ loading: false });
    }
  },

  setCombinedTasksAndIdeas: (tasksAndIdeas) => set({ combinedTasksAndIdeas: tasksAndIdeas }),

  fetchExecutions: async (dioData) => {
    const { token } = get();
    console.log(token, "<============ token)");
    if (!dioData || dioData.length === 0) {
      set({ error: "No data available for executions." });
      return;
    }
  
    set({ loading: true, error: null });
    try {
      const tasks = await Promise.all(
        dioData.map((dio) =>
          axios
            .get(`${process.env.REACT_APP_BACKEND_URL}/DIO/execution?dioId=${dio.id}`, {
              headers: { Authorization: `Bearer ${token}` }, // Inclure le token si nécessaire
            })
            .then((response) => ({
              dioId: dio.id, // Ajouter dioId explicitement
              data: response.data.combinedData,
            })),
        ),
      );
  
      const combinedTasks = tasks.flatMap(({ dioId, data }) =>
        data.map((item) => ({ ...item, dioId })), // Attache dioId à chaque tâche
      );
  
      set({ dioTasks: combinedTasks });
    } catch (error) {
      console.error("Error in fetchExecutions:", error);
      set({ error: "Error fetching executions." });
    } finally {
      set({ loading: false });
    }
  },
  

  addAchievement: async (achievement) => {
    console.log("Selected Organization:", achievement.selectedOrganizationId);
    const { setLoading, setDepartHours, selectedTalent, token, axiosInstance } = get();
    setLoading(true);
    try {
      console.log("1-Données envoyées : ", {
        userId: localStorage.getItem("userId"),
        executionDescription: achievement.title,
        dioId: achievement.selectedOrganizationId,
        status: "In review",
        input_link: achievement.link,
        Expected_date: achievement.date,
        instructions: achievement.positiveAspect,
      });
  
      const start = Date.now();
      const workDoneRes = await axiosInstance.post(
        process.env.REACT_APP_BACKEND_URL + "/execution/workDone",
        {
          userId: localStorage.getItem("userId"),
          executionDescription: achievement.title,
          dioId: achievement.selectedOrganizationId,
          status: "In review",
          link: achievement.link,
          Expected_date: achievement.date,
          instructions: achievement.positiveAspect,
        },
        { headers: { Authorization: `Bearer ${token}` } },
      );
      console.log("2-Données envoyées : ", {
        userId: localStorage.getItem("userId"),
        executionDescription: achievement.title,
        dioId: achievement.selectedOrganizationId,
        status: "In review",
        link: achievement.link,
        Expected_date: achievement.date,
        instructions: achievement.positiveAspect,
      });
      console.log("Réponse de WorkDone API :", workDoneRes.data);
  
      const workDoneTime = Date.now() - start;
      console.log(`WorkDone request took ${workDoneTime} ms`);
      const executionID = workDoneRes.data.insertId;
      const dataReview = {
        userId: localStorage.getItem("userId"),
        executionId: executionID,
        comment_highlight: achievement.positiveAspect,
        comment_improvement: null,
        bts: achievement.teamValue,
        userName: localStorage.getItem("userName"),
        dioId: achievement.selectedOrganizationId,
        complexity: achievement.complexity,
        repetition: achievement.frequency,
        link: achievement.link,
      };
  
      const reviewRes = await axiosInstance.post(
        process.env.REACT_APP_BACKEND_URL + "/review/selfReview",
        dataReview,
        { headers: { Authorization: `Bearer ${token}` } },
      );
  
      let newDepartHours = reviewRes.data.data.responseValue;
      let updatedStatut = "In review";
  
      // Logique pour changer le statut si la date est dans le futur

      // Logique pour changer le statut
      const currentDate = new Date();
      const expectedDate = new Date(achievement.date);
      
      // Ajout des scores pour vérifier leur valeur
      const reactivityScore = achievement.reactivityScore || 0;
      const resultQualityScore = achievement.resultQualityScore || 0;
      const teamValueScore = achievement.teamValueScore || 0;
      
      if (
        reactivityScore === 0 &&
        resultQualityScore === 0 &&
        teamValueScore === 0 &&
        expectedDate > currentDate
      ) {
        updatedStatut = "On going"; // Mettre à jour le statut en "On going" si les scores sont 0 et la date dans le futur
        console.log(
          "Status set to 'On going' because scores are 0 and expected date is in the future."
        );
      } else {
        if (selectedTalent === "Another Talent") updatedStatut = "Open";
        if (newDepartHours <= 20) updatedStatut = "Achieved";
      }
      
      console.log("Updated status after checking scores and date:", updatedStatut);
  
      const DEADLINES = { DEAD3: 72 };
      let remaining_time = null;
      if (newDepartHours > 20) {
        newDepartHours = DEADLINES.DEAD3;
        remaining_time = 60 * 60 * newDepartHours;
      }
  
      setDepartHours(newDepartHours);
  
      // Check if the status is "On going", and if so, update status_ to "Achieved"
      if (   reactivityScore !== 0 &&
        resultQualityScore !== 0 &&
        teamValueScore !== 0 && (updatedStatut === "On going")) {
        updatedStatut = "Achieved"; // Set the status to "Achieved"
        console.log("Status changed to 'Achieved' as it was 'On going'.");
      }

      console.log("Final status to be sent:", updatedStatut);

      // Send updated status_ to the back-end
      const updateStatusRes = await axiosInstance.post(
        process.env.REACT_APP_BACKEND_URL + "/execution/updateStatus",
        {
          executionId: executionID,
          status: updatedStatut,
          remaining_time: remaining_time,
          executionLink: achievement.link,
          Expected_date: achievement.date,
        },
        { headers: { Authorization: `Bearer ${token}` } },
      );
      console.log("Response from /updateStatus:", updateStatusRes.data);
    } catch (error) {
      console.error("There was an error with the request:", error);
      alert("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  },



  
    // Nouvelle fonction handleReactivity
    submitFeedback: async (feedback) => {
      const { token } = get(); // Récupère le token
    
      // Utilisation directe des données passées dans feedback
      const { positiveAspect, improvementSuggestion, executionId, teamValueScore, resultQualityScore, reactivityScore } = feedback;
      if (!executionId) {
        console.error("Execution ID is missing!");
        return; // Empêche l'envoi des données executionId est absent
      }
      const data = {
        executionId,
        userId: localStorage.getItem("userId"),
        dioId: localStorage.getItem("DOId"),
        comment_highlight: positiveAspect,
        comment_improvement: improvementSuggestion,
        bts: teamValueScore,
        result_quality: resultQualityScore,
        reactivity: reactivityScore,
      };
      // Affichage des données envoyées pour le débogage
      console.log("Données envoyées à l'API:", data);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/review/peerReview`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log("Peer review success:", response.data);
      } catch (error) {
        console.error("Error during peer review:", error);
        if (error.response) {
          console.error("Erreur côté serveur:", error.response.data);
        }
      }
    },
    // Nouvelle méthode pour récupérer les idées
    fetchIdeas: async (dioData) => {
      const { token, axiosInstance } = get();
      try {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_BACKEND_URL}/idea/on_going_idea/${dioData[0].id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        // Vérifier et mettre à jour le statut des idées
        const updatedIdeas = await Promise.all(response.data.map(async idea => {
          if (idea.status_ === "On going idea") {
            const creationDate = new Date(idea.creation_date);
            const now = new Date();
            const daysSinceCreation = Math.floor((now - creationDate) / (1000 * 60 * 60 * 24));
            
            if (daysSinceCreation >= 7) {
              try {
                // Mettre à jour le statut dans le backend
                await axiosInstance.post(
                  `${process.env.REACT_APP_BACKEND_URL}/idea/update_status`,
                  { 
                    id_idea: idea.id,
                    status_: "Achieved idea"
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                      "Content-Type": "application/json",
                    },
                  }
                );
                
                return {
                  ...idea,
                  status_: "Achieved idea"
                };
              } catch (error) {
                console.error("Error updating idea status:", error);
                return idea;
              }
            }
          }
          return idea;
        }));

        set({ ideas: updatedIdeas });
      } catch (error) {
        console.error("Error fetching ideas:", error);
        throw error;
      }
    },
  
    
  
    // Add sendIdea to your store
    sendIdea: async (
      title,
      describeIdea,
      importantIdea,
      date,
      selectedOrganizationId,
      status,
      isDraft = false
    ) => {
      const { token, axiosInstance } = get();
      set({ loading: true, error: null });

      const data = {
        title: title || "Untitled",
        description: describeIdea || "",
        importance: importantIdea || "",
        expectedDate: date || null,
        id_do: Number(selectedOrganizationId),
        id_creator: localStorage.getItem("userId"),
        status_: status,
        isDraft: isDraft
      };

      try {
        const response = await axiosInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/idea/on_going_idea`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        return response.data;
      } catch (error) {
        console.error("Error creating idea:", error);
        throw error;
      } finally {
        set({ loading: false });
      }
    },
    sendFeedbackIdea: async (
      idIdea, 
      feelingIdea, 
      contribution, 
      idContributor, 
      vote
    ) => {
      const { token, axiosInstance } = get(); // Get the token and axiosInstance from the store
      set({ loading: true, error: null });
      if (!idIdea || !feelingIdea || !contribution || !idContributor || vote === undefined || vote === null) {
        console.error("Missing data:", { idIdea, feelingIdea, contribution, idContributor, vote });
        return;
      }
      if (!idIdea) console.error("Missing idIdea");
      if (!feelingIdea) console.error("Missing feelingIdea");
      if (!contribution) console.error("Missing contribution");
      if (!idContributor) console.error("Missing idContributor");
      if (!vote) console.error("Missing vote");

      const data = {
        id_idea: idIdea,
        feeling_idea: feelingIdea,
        contribution: contribution,
        id_contributor: idContributor,
        vote: vote
      };
    
      console.log(data, "<============================   Feedback data");
    
      try {
        const response = await axiosInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/idea/feeling/idea`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        console.log("Feedback sent successfully:", response.data);
      } catch (error) {
        console.error("Error sending feedback:", error);
        set({ error: "Unable to send feedback." });
      } finally {
        set({ loading: false });
      }
    },
    createTalentsHub: async (payload) => {
      console.log("Début de la création du Collab Space avec les données :", payload); // Log de début avec les données envoyées
      set({ isLoading: true, error: null }); // Début du chargement
    
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/CreateSponsorizeDO/createDO`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });
    
        console.log("Réponse HTTP reçue :", response); // Log de la réponse HTTP brute
        const data = await response.json(); // Récupération des données JSON
    
        console.log("Données reçues après la création du Collab Space :", data); // Log des données reçues
        console.log("Détails de la structure de la réponse :", JSON.stringify(data, null, 2)); // Inspecter la réponse complète
    
        if (data && data.message === "Do creation réussie") {
          console.log("Création du Collab Space réussie.");
          console.log("Détails de l'organisation créée :", data.data); // Affiche les détails de l'organisation
    
          // Utilisation de axios pour récupérer les informations supplémentaires basées sur l'ID du DO
          if (data.data && data.data.id_do_creator && data.data.nom_do) {
            // On utilise l'ID du créateur du DO et le nom du DO pour récupérer plus d'infos
            axios.get(`${process.env.REACT_APP_BACKEND_URL}/CreateSponsorizeDO/infoDOcreated?userId=${data.data.id_do_creator}&nameDo=${data.data.nom_do}`)
              .then((response) => {
                const fetchedData = response.data;
                console.log("Données reçues après récupération des infos DO :", fetchedData);
                
                if (fetchedData && fetchedData.length > 0) {
                  // Si des données sont retournées, on peut mettre à jour l'état avec l'ID du DO et autres détails
                  set({ talentsHubData: { ...data.data, id_do: fetchedData[0].id }, isLoading: false });
                } else {
                  console.log("Aucune donnée supplémentaire reçue pour le DO");
                }
              })
              .catch((error) => {
                console.error("Erreur lors de la récupération des données DO :", error);
                set({ error: "Erreur lors de la récupération des informations DO", isLoading: false });
              });
          } else {
            console.log("Données de l'organisation non valides pour récupérer plus d'infos");
            set({ talentsHubData: data.data, isLoading: false }); // Mise à jour de l'état avec les données reçues
          }
        } else {
          const errorMessage = data.message || "Unknown error during Collab Space creation";
          console.log("Erreur dans les données :", errorMessage); // Log des erreurs dans les données
          set({ error: errorMessage, isLoading: false });
          throw new Error(errorMessage);
        }
    
        return data; // Retourne les données pour un traitement ultérieur si nécessaire
      } catch (error) {
        console.error("Erreur lors de la création du Collab Space :", error); // Log détaillé de l'erreur
        set({ error: error.message, isLoading: false }); // Mise à jour de l'état avec le message d'erreur
        throw error; // Propager l'erreur pour la gérer côté composant
      }
    },
    handleCreateDO : () => {
      // Récupération des données du DO
     const {setIdDOcreated, nameDO} = get()
      const CeoDoId=localStorage.getItem("userId")
      axios.get(`${process.env.REACT_APP_BACKEND_URL}/CreateSponsorizeDO/infoDOcreated?userId=${CeoDoId}&nameDo=${nameDO}`)
        .then(response => {
          const data = response.data;
          console.log("Données du DO récupérées :", data);
    
          // Vérification si les données sont valides et mise à jour de l'id du DO
          if (data && data.id) {
            setIdDOcreated(data.id); // On met à jour l'ID du DO avec la donnée récupérée
            console.log("idDO créé :", data.id);
    
            // Préparation des données pour l'ajout du DO à un créateur
            const id_membre = localStorage.getItem("userId");
            const id_do = data.id;
            const sponsored = '0'; // Le membre est par défaut non sponsorisé
    
            // Envoi des données à la route /addDOtoCreator
            const userData_ = { id_membre, id_do, sponsored };
    
            fetch(process.env.REACT_APP_BACKEND_URL + "/CreateSponsorizeDO/addDOtoCreator", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(userData_),
            })
            .then((response) => {
              if (response.status === 200) {
                response.json().then((data) => {
                  console.log("Réponse de l'ajout du DO à un créateur :", data);
                  localStorage.setItem("do_ids", JSON.stringify(data.do_ids)); // Sauvegarde des IDs du DO
                });
              }
            })
            .catch((error) => {
              console.error("Erreur lors de la création du DO pour le créateur :", error);
            });
    
          } else {
            console.error('Aucune donnée valide reçue du serveur');
          }
        })
        .catch((error) => {
          console.error("Erreur lors de la récupération des infos du DO :", error);
        });
    },
    
    
    
    
    
    
    
    
    
    
    
    

    // Ajouter ces deux nouvelles fonctions dans le store
    createNewIdea: async (title, describeIdea, importantIdea, date, selectedOrganizationId) => {
      const { token, axiosInstance } = get();
      set({ loading: true, error: null });

      const data = {
        title: title || "Untitled",
        description: describeIdea || "",
        importance: importantIdea || "",
        expectedDate: date || null,
        id_do: Number(selectedOrganizationId),
        id_creator: localStorage.getItem("userId"),
        status_: "On going idea"
      };

      try {
        const response = await axiosInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/idea/on_going_idea`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        return response.data;
      } catch (error) {
        console.error("Error creating idea:", error);
        throw error;
      } finally {
        set({ loading: false });
      }
    },

    createDraftIdea: async (title, describeIdea, importantIdea, date, selectedOrganizationId) => {
      const { token, axiosInstance } = get();
      set({ loading: true, error: null });

      const data = {
        title: title || "Untitled Draft",
        description: describeIdea || "",
        importance: importantIdea || "",
        expectedDate: date || null,
        id_do: Number(selectedOrganizationId),
        id_creator: localStorage.getItem("userId"),
        status_: "Draft idea"
      };

      try {
        const response = await axiosInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/idea/on_going_idea`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        return response.data;
      } catch (error) {
        console.error("Error creating draft:", error);
        throw error;
      } finally {
        set({ loading: false });
      }
    },

    updateIdea: async (
      ideaId,
      title,
      describeIdea,
      importantIdea,
      date,
      selectedOrganizationId,
      status = "On going idea"
    ) => {
      const { token, axiosInstance } = get();
      set({ loading: true, error: null });

      // Formater la date pour MySQL (YYYY-MM-DD)
      const formattedDate = date ? date.split('T')[0] : null;

      const data = {
        id_do: Number(selectedOrganizationId),
        title: title || "",
        id_creator: localStorage.getItem("userId"),
        description: describeIdea || "",
        importance: importantIdea || "",
        expectedDate: formattedDate,
        status_: status
      };

      console.log("Updating idea with data:", data);

      try {
        const response = await axiosInstance.put(
          `${process.env.REACT_APP_BACKEND_URL}/idea/update_idea/${ideaId}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        
        await get().fetchIdeas(get().dioData);
        return response.data;
      } catch (error) {
        console.error("Error updating idea:", error.response?.data || error);
        throw error;
      } finally {
        set({ loading: false });
      }
    },

    cooptTalent: async (firstName, lastName, email, message, dioId) => {
      const { token, axiosInstance, setLoading } = get();
      setLoading(true);

      try {
        // Vérifier l'email avec l'URL complète
        const verifyResponse = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/cooptTalent/verifyMail?email=${email}&doId=${dioId === "sponsoring" ? "0" : dioId}`
        ).then(res => res.json());

        const { emailExists, talentExists, firstName: existingFirstName } = verifyResponse;

        if (talentExists) {
          throw new Error("This talent is already in the DO.");
        }

        const userName = localStorage.getItem("userName");

        if (dioId === "sponsoring") {
          // Pour le sponsoring, utiliser la route spécifique
          const userData = {
            FirstName: emailExists ? existingFirstName : firstName,
            LastName: lastName,
            Email: email,
            password: "123456",
            first_connection: "1", // Laissé à 1 pour que Welcome.js puisse le gérer
            sponsored: "1",
            UserNameId: localStorage.getItem("userId"),
            subjectMail: `${userName} invites you to Thankward`,
            textMail: `Hi ${emailExists ? existingFirstName : firstName},
Join me on Thankward to explore an amazing experience of collaboration.
You'll see, it's easy, and we earn lots of Thanks, the future of appreciation-based rewards.
You'll receive your password in a separate message.
Follow the link below to sign up with your email address: ${email}
www.thanksandshare.com
See you soon!`
          };

          const response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/CreateSponsorizeDO/sponsorize`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(userData)
            }
          );

          if (!response.ok) {
            const errorData = await response.text();
            console.error("Server response:", errorData);
            throw new Error("Failed to sponsorize talent");
          }

          // Retourner les données nécessaires pour Welcome.js
          return {
            success: true,
            data: {
              userId: localStorage.getItem("userId"),
              firstName: firstName,
              lastName: lastName,
              email: email,
              sponsored: "1"
            }
          };
        } else {
          // Pour les autres cas (non-sponsoring)
          if (emailExists) {
            const userData = {
              Email: email,
              id_do: dioId,
              UserNameId: localStorage.getItem("userId"),
              subjectMailexist: `${userName} invites you to ${localStorage.getItem("DOName")}`,
              textMailexist: `Hi ${existingFirstName},
Join me at "${localStorage.getItem("DOName")}," an exceptional Digital Organization where you can thrive in a great
team and contribute to an exciting project.
See you soon!"
www.thanksandshare.com`
            };

            const response = await fetch(
              `${process.env.REACT_APP_BACKEND_URL}/cooptTalent/api/cooptExsistedMember`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(userData)
              }
            );

            if (!response.ok) {
              const errorData = await response.text();
              console.error("Server response:", errorData);
            }

            return { success: true };
          } else {
            const userData = {
              FirstName: firstName,
              LastName: lastName,
              Email: email,
              password: "123456",
              id_do: dioId,
              first_connection: "1",
              UserNameId: localStorage.getItem("userId"),
              subjectMail: `${userName} invites you to ${localStorage.getItem("DOName")}`,
              textMail: `Hi ${firstName} ${lastName},
Join me on Thankward to explore an amazing experience of collaboration.
You'll see, it's easy, and we earn lots of Thanks, the future of appreciation-based rewards.
You'll receive your password in a separate message.
Follow the link below to sign up with your email address: ${email}
www.thanksandshare.com
See you soon!`
            };

            const response = await fetch(
              `${process.env.REACT_APP_BACKEND_URL}/cooptTalent/api/coopt`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(userData)
              }
            );

            if (!response.ok) {
              const errorData = await response.text();
              console.error("Server response:", errorData);
            }

            return { success: true };
          }
        }
      } catch (error) {
        console.error("Error coopt talent:", error);
        throw error.response?.data?.message || error.message || "Error inviting talent";
      } finally {
        setLoading(false);
      }
    }
}));

// Ajouter une fonction pour vérifier et mettre à jour le statut des idées
const checkAndUpdateIdeasStatus = (ideas) => {
  const now = new Date();
  
  return ideas.map(idea => {
    if (idea.status_ === "On going idea") {
      const creationDate = new Date(idea.creation_date);
      const daysSinceCreation = Math.floor((now - creationDate) / (1000 * 60 * 60 * 24));
      
      if (daysSinceCreation >= 7) {
        return {
          ...idea,
          status_: "Achieved idea"
        };
      }
    }
    return idea;
  });
};

export default useStore;
