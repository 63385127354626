import React, { useState, useEffect, useRef } from "react";

import TextAreaWithLabel from "../../Components/TextAreaWithLabel";

import ResourceLink from "./Components/ResourceLink";

import arrow from "../../images/icones/arrow.png";

//import t100h from '../../images/t100h.png';
import profile from "../../images/icones/personna.png";

import useStore from "../../API/store";

const NewIdeaView = ({ handleGoBack, dioData, setSelectedStatus }) => {
  const {
    link,
    setLink,
    isInputVisible,
    setIsInputVisible,
    createNewIdea,
    createDraftIdea,
    setLoading,
    fetchIdeas,
    setError
  } = useStore();
  const [describeIdea, setDescribeIdea] = useState("");
  const [importantIdea, setImportantIdea] = useState("");
  const [selectedOrganizationId, setSelectedOrganizationId] = useState(1);
  const [title, setTitle] = useState("");
  const [date, setDate] = useState(null);

  const [selectedOrganization, setSelectedOrganization] = useState(
    "Choose a Collab Space",
  );
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const [menuOpenHub, setMenuOpenHub] = useState(false);

  const handleLinkClick = () => setIsInputVisible(!isInputVisible);
  const handleInputChange = (event) => setLink(event.target.value);

  const validateForm = () => {
    return (
      title.trim() !== "" &&
      describeIdea.trim() !== "" &&
      importantIdea.trim() !== "" &&
      date &&
      selectedOrganizationId !== "" &&
      selectedOrganization !== "Choose Organization"
    );
  };
  
  const onSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      alert("Please fill all required fields.");
      return;
    }
    setLoading(true);
    
    createNewIdea(
      title,
      describeIdea,
      importantIdea,
      date,
      Number(selectedOrganizationId)
    )
    .then(() => {
      console.log("IDEA successfully submitted!");
      fetchIdeas(dioData);
      setTitle("");
      setDescribeIdea("");
      setDate("");
      setLink("");
      handleGoBack();
      setSelectedStatus("all");
    })
    .catch((error) => {
      const errorMessage = error.response?.data?.message || error.message;
      setError(errorMessage);
    })
    .finally(() => {
      setLoading(false);
    });
  };

  
  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleCalendar = () => {
    setIsCalendarOpen(true);
  };
  const handleDateChange = (e) => {
    setDate(e.target.value);
  };
  const handleOrganizationClick = (organization, id) => {
    setSelectedOrganization(organization);
    setSelectedOrganizationId(id);
    setMenuOpenHub(false);
  };

  const newIdeaViewRef = useRef();

  useEffect(() => {
    if (newIdeaViewRef.current) {
      newIdeaViewRef.current.scrollIntoView({
        block: "start",
        behavior: "instant",
      });
    }
  }, []);

  const handleSaveAsDraft = async (e) => {
    e.preventDefault();
    if (!selectedOrganizationId) {
      alert("Please select a Collab Space");
      return;
    }
    setLoading(true);
    
    try {
      await createDraftIdea(
        title || "Untitled Draft",
        describeIdea,
        importantIdea,
        date,
        Number(selectedOrganizationId)
      );
      
      await fetchIdeas(dioData);
      setTitle("");
      setDescribeIdea("");
      setImportantIdea("");
      setDate("");
      setLink("");
      handleGoBack();
      setSelectedStatus("all");
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      ref={newIdeaViewRef}
      className="w-1/2 bg-rightGreyBg p-32 overflow-y-auto max-h-[calc(110vh-64px)]"
    >
         <div className="flex justify-between">
        <img
          src={arrow}
          alt="retour"
          className="w-12 h-12 cursor-pointer"
          onClick={handleGoBack}
        />
        {!isCalendarOpen && (
          <div
            onClick={handleCalendar}
            className="text-xs w-[92px] h-[180px] p-4 pl-3 pr-3 mb-6 flex flex-col items-start justify-center text-white bg-stone-950 rounded-lg font-silka-mono cursor-pointer"
          >
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6 mb-2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8.25 3v1.5m7.5-1.5v1.5M3.75 9h16.5M4.5 5.25h15a2.25 2.25 0 012.25 2.25v11.25a2.25 2.25 0 01-2.25 2.25H4.5a2.25 2.25 0 01-2.25-2.25V7.5a2.25 2.25 0 012.25-2.25z"
                  />
                </svg>
            <p>
              When would <br /> you like <br /> to see the idea <br /> come to
              life?
            </p>
          </div>
        )}
        {isCalendarOpen && (
          <input
            type="date"
            className="text-xs w-[132px] h-[60px] p-4 pl-3 pr-3 mb-6 flex items-center justify-center text-white bg-stone-950 rounded-lg font-silka-mono cursor-pointer"
            onChange={handleDateChange}
            value={date}
            min={new Date().toISOString().split("T")[0]}
          />
        )}
      </div>

      <div className="flex space-x-4">
        <div className="flex justify-center items-center w-[240px] h-8 border border-1 border-black rounded-xl relative">
          <button
            type="button"
            className="flex justify-center items-center w-full h-full text-xs font-silka-mono uppercase p-2"
            onClick={() => setMenuOpenHub(!menuOpenHub)}
          >
            {selectedOrganization}
            <svg
              className="ml-auto h-5 w-5 text-gray-900"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          {menuOpenHub && (
            <div className="absolute right-0 z-10 mt-[200px] w-[240px] origin-top-right rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                {dioData.map((item, index) => (
                  <button
                    key={index}
                    className="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
                    onClick={() => handleOrganizationClick(item.name, item.id)}
                  >
                    {item.name}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
        {/* <div className="flex justify-center items-center w-[160px] h-8 border border-1 border-black rounded-xl">
                    <p className="font-silka-mono uppercase text-xs text-center p-2">
                        add to a project
                    </p>
                </div> */}
      </div>

      {!isEditingTitle ? (
        <textarea
          value={title}
          onChange={handleTitleChange}
          onBlur={() => setIsEditingTitle(false)}
          placeholder={`Title
of your idea`}
          maxLength={40}
          autoFocus
          className="font-silka-mono font-semibold text-4xl underline italic mt-6 bg-transparent border-none outline-none resize-none overflow-hidden h-18 placeholder-black"
          rows={2}
        />
      ) : (
        <div className="font-silka-mono font-semibold text-4xl mt-6 underline italic cursor-pointer">
          {title.split("\n").map((line, index) => (
            <span key={index}>
              {line}
              {index < title.split("\n").length - 1 && <br />}
            </span>
          ))}
        </div>
      )}
      <div className="flex space-x-4 mt-10">
        <div className="flex justify-start items-center w-[120px] h-8 rounded-xl">
          <img className="w-8 h-8 rounded-full" alt="profil" src={profile} />
          <p className="font-silka-mono text-xs text-center p-2">
            {dioData[0]?.member.first_name}
          </p>
        </div>
      </div>

      <form onSubmit={onSubmit} className="mt-10">
        <TextAreaWithLabel
          label={<>Describe your idea</>}
          value={describeIdea}
          placeholder="Provide a brief description"
          onChange={(e) => setDescribeIdea(e.target.value)}
          bg="bg-rightGreyBg"
        />
        <TextAreaWithLabel
          label={<>Why your idea is important?</>}
          value={importantIdea}
          placeholder="Provide a brief description"
          onChange={(e) => setImportantIdea(e.target.value)}
          bg="bg-rightGreyBg"
        />

<ResourceLink
        handleLinkClick={handleLinkClick}
        isInputVisible={isInputVisible}
        link={link}
        handleInputChange={handleInputChange}
      />

        <div className="flex flex-col space-y-2">
          <div className="flex flex-row items-center space-x-4 mt-10">
            <button
              type="submit"
              className="w-[15em] px-8 py-4 bg-black text-white rounded-[50px] font-silka-mono uppercase"
            >
              share your idea
            </button>
            {/* <img src={t100h} alt="thanks" className="w-22 h-10" /> */}
          </div>

          <button
            type="button"
            onClick={handleSaveAsDraft}
            className="w-[15em] px-8 py-4 bg-rightGreyBg border-2 border-black text-black rounded-[50px] font-silka-mono uppercase"
          >
            Save for later
          </button>
        </div>
      </form>
    </div>
  );
};

export default NewIdeaView;
