import React, { useEffect, useRef } from 'react';
import Rating from "./Components/Rating"
import arrow from "../../images/icones/arrow.png"
import folder from "../../images/icones/Union.png"
import heart from "../../images/heart2.png"

import mourad from "../../images/MK.png"
import guillaume from "../../images/GH.png"
import oussman from "../../images/OD.png"

const FakeComponent = ({handleGoBack }) => {
    const usersList = ["Guillaume", "Mourad"]
    const profilePics = [guillaume, mourad]
    const picoussman =["oussman"]
    const profilpicOussman = [oussman]

    useEffect(() => {
        if (fakeComponentViewRef.current) {
          fakeComponentViewRef.current.scrollIntoView({
            block: "start",
            behavior: "instant",
          });
        }
      }, []);

    const fakeComponentViewRef = useRef();
    return ( 
         <div    ref={fakeComponentViewRef} className="w-1/2 bg-gains3 p-32 overflow-y-auto max-h-[calc(110vh-64px)]">
          <div className="flex justify-between" >
          <img src={arrow} alt="retour" className="w-12 h-12 cursor-pointer" onClick={handleGoBack}/>
          <div className=" text-xs w-[32px] h-[50px] p-1 pl-3 pr-3 mb-6 flex items-center justify-center  text-white  bg-stone-950 rounded-lg font-silka-mono">28 <br/>10</div>
          </div>
          <p className="font-silka-mono mt-10">Thankward by MKIF</p>
          <p className="font-silka-mono font-bold text-3xl mt-6">intégrer le "Bayesian Truth Serum" à l’algorithme de Thanks</p>

          <div className="flex items-center space-x-2 mt-10">
        {usersList.map((user, index) => (
            <React.Fragment key={index}>
              {profilePics[index] && (
                <img src={profilePics[index]} alt={user} className="w-8 h-8 rounded-full" />
              )}
              <p className='text-sm font-silka-mono'>{user}</p>
              {index < usersList.length - 1 && <span> &gt; </span>}
            </React.Fragment>
         ))}
    </div>
         <div className="flex justify-center items-center w-[100px] h-12 border-2 border-black mt-20 rounded-2xl">
            <p className="font-silka-mono font-bold uppercase text-center p-2">
              brief
            </p>
         </div>
         <p className="font-silka-mono mt-10">
            Pour rendre robuste le système de Thanks basé sur du feedback croisé, utiliser le modèle mathématique du BTS développé au MIT pour s'assurer de l'honnêteté des participants et sanctionner ou récompenser les comportements vertueux.
         </p>
          <a href="https://journals.sagepub.com/doi/abs/10.1509/jmr.09.0039" target="_blank" rel="noopener noreferrer">
              <img className="cursor-pointer mt-10" src={folder} alt="folder" />
          </a>
         <div className="flex justify-center items-center w-[170px] h-12 border-2 border-black mt-20 rounded-2xl">
            <p className="font-silka-mono font-bold uppercase text-center p-2">
              deliverables
            </p>
         </div>
         <p className="font-silka-mono mt-10">
         on aurait dit que le BTS a été créé pour Thankward, c'est juste le modèle mathématique déjà éprouvé par les meilleurs chercheurs au monde MIT, Harvard, X...qui nous permet de rendre notre Thanks model basé sur le consensus et la pertinence extrêmement robuste et difficile à imiter, Merci Guillaume
         </p>
         <a href="https://drive.google.com/drive/folders/1uDlb_XKlI5WjxepU0DoMGnxNU_JiKjMt" target="_blank" rel="noopener noreferrer">
              <img className="cursor-pointer mt-10" src={folder} alt="folder" />
          </a>
          <div className="bg-black p-4 rounded-2xl  mt-12">
        <Rating label="Value" points={4} rating={2} />
        <Rating label="Complexity" points={4} rating={3} />
        <Rating label="Originality" points={4} rating={3} />
          </div>
          <div className="flex justify-center items-center w-[140px] h-12 border-2 border-black mt-20 rounded-2xl">
            <p className="font-silka-mono font-bold uppercase text-center p-2">
              Feed-back
            </p>
         </div>
         <div className="relative font-silka-mono p-4 rounded-3xl max-w-xs border-2 border-black mt-10 bg-gains3">
            <p>Slide assez clair, besoin de quelques questions sur des points précis au téléphone mais je peux déjà commencer à coder</p>
            <div className="absolute -bottom-2 left-10 w-0 h-0 border-t-8 border-t-black border-r-8 border-r-transparent border-l-8 border-l-transparent"></div>
          </div>


            <div className="flex items-center space-x-2 mt-10">
                {picoussman.map((user, index) => (
                <React.Fragment key={index}>
                  {profilpicOussman[index] && (
                    <img src={profilpicOussman[index]} alt={user} className="w-8 h-8 rounded-full" />
                  )}
                  <p className='text-sm font-silka-mono'>{user}</p>
                </React.Fragment>
                ))}
           </div>

           <div className="relative font-silka-mono p-4 rounded-3xl max-w-xs border-2 border-black mt-10 bg-gains3">
            <p>Bravo, très belle adaptation des travaux de  Drazen Prelec à qui je parlerai du modèle Thankward pour avis</p>
            <div className="absolute -bottom-2 left-10 w-0 h-0 border-t-8 border-t-black border-r-8 border-r-transparent border-l-8 border-l-transparent"></div>
          </div>

          <div className="flex items-center space-x-2 mt-10">
          <img src={guillaume} alt="Guillaume" className="w-8 h-8 rounded-full" />
          <p className='text-sm font-silka-mono'>Guillaume</p>
           </div>

           <div className="bg-black p-4 rounded-2xl  mt-12">
            <Rating style={{marginRight:20}} label="Value" points={4} rating={2} />
            <Rating label="Quality" points={5} rating={3} />
            <Rating label="Reactivity" points={5} rating={3} />
            </div>
            <div className="w-[132px] h-[66px] flex items-center justify-center bg-stone-950 rounded-[20px] mt-10">
              <p className="text-4xl text-gains3  text-center font-silka-mono">109</p>
              <img className="w-8 h-8 p-1" alt="Thanks" src={heart} />
            </div>
        </div>  )

}


export default FakeComponent;