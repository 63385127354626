import React, { useEffect, useRef, useState } from "react";
import PercentageViz from "./PercentageViz.js";

import arrow from "../../../images/icones/arrow.png";
import idea from "../../../images/icones/Idea.png";
import profil from "../../../images/icones/personna.png";
import IdeaCountdown from "../../../Components/IdeaCountdown.js";

const IdeaView = ({ handleGoBack, cardDetails, dioData }) => {
  const [isTimeUp, setIsTimeUp] = useState(false);
  const userId = localStorage.getItem("userId");
  const isCreator = Number(cardDetails.id_talent_creator) === Number(userId);

  const completName = `${cardDetails.membre_first_name || 'Prénom inconnu'} ${cardDetails.performor_name || 'Nom inconnu'}`;
  const usersList = [completName];
  const profilePics = [profil];

  const ideaViewRef = useRef();

  // Trouver le nom de la collab space correspondant à l'idée
  const collabSpaceName = dioData?.find(dio => dio.id === cardDetails.id_do)?.name || "Collab Space";

  // Fonction pour vérifier si le temps est écoulé
  const checkTimeUp = () => {
    const now = new Date();
    const creation = new Date(cardDetails.creation_date);
    const deadline = new Date(creation.getTime() + (7 * 24 * 60 * 60 * 1000));
    return now >= deadline;
  };

  useEffect(() => {
    setIsTimeUp(checkTimeUp());
    const timer = setInterval(() => {
      setIsTimeUp(checkTimeUp());
    }, 1000);
    
    return () => clearInterval(timer);
  }, [cardDetails.creation_date]);

  useEffect(() => {
    if (ideaViewRef.current) {
      ideaViewRef.current.scrollIntoView({
        block: "start",
        behavior: "instant",
      });
    }
  }, []);

  return (
    <div
      ref={ideaViewRef}
      className="w-1/2 bg-gains3 p-32 overflow-y-auto max-h-[calc(110vh-64px)]"
    >
      <div className="flex justify-between">
        <img
          src={arrow}
          alt="retour"
          className="w-12 h-12 cursor-pointer"
          onClick={handleGoBack}
        />
        <div className=" text-xs w-[40px] h-[60px] p-2 mb-6 flex items-center justify-center text-white bg-stone-950 rounded-lg font-silka-mono">
          <img alt="idea" src={idea} className="w-full h-auto" />
        </div>
      </div>
      <p className="font-silka-mono mt-10">{collabSpaceName}</p>
      <p className="font-silka-mono font-semibold text-4xl mt-6">
        {cardDetails?.exec_description}
      </p>

      <div className="flex items-center space-x-2 mt-10">
        {usersList.map((user, index) => (
          <React.Fragment key={index}>
            {profilePics[index] && (
              <img
                src={profilePics[index]}
                alt={user}
                className="w-8 h-8 rounded-full"
              />
            )}
            <p className="text-sm font-silka-mono">{user}</p>
            {index < usersList.length - 1 && <span> &gt; </span>}
          </React.Fragment>
        ))}
      </div>

      <div className="flex justify-center items-center w-[100px] h-12 border-2 border-black mt-20 rounded-2xl transform -translate-x-4">
        <p className="font-silka-mono font-bold uppercase text-center p-2">
          picth
        </p>
      </div>

      <p className="font-silka-mono mt-10">
        {cardDetails.comment_highlight} <br/>
        {cardDetails.comment_improvement}
      </p>
      <>
        <div className="flex justify-center items-center w-[140px] h-12 border-2 border-black mt-20 rounded-2xl transform -translate-x-4">
          <p className="font-silka-mono font-bold uppercase text-center p-2">
            Feed-back
          </p>
        </div>


        {cardDetails.feedbacks && cardDetails.feedbacks.map((feedback, index) => (
  <>
    <div key={index} className="relative font-silka-mono rounded-3xl max-w-xs border-2 border-black mt-10 bg-gains3">
      <p className="p-4">
        {feedback.feeling_idea}
        </p>
        <div className="my-2 border-t border-black w-full"></div>
        <p className="p-4">
        {feedback.contribution}
        </p>

      {/* Triangle en bas à gauche */}
      <div className="absolute -bottom-2 left-10 w-0 h-0 border-t-8 border-t-black border-r-8 border-r-transparent border-l-8 border-l-transparent"></div>
    </div>

    <div className="flex items-center space-x-2 mt-10">
      <img src={profil} alt="user" className="w-8 h-8 rounded-full" />
      <p className="text-sm font-silka-mono">{feedback.first_name + " " + feedback.name  || null}</p>
    </div>
  </>
))}



      </>

      <PercentageViz yesCount={cardDetails.yesCount} noCount={cardDetails.noCount} />
      <IdeaCountdown creationDate={cardDetails.creation_date} />
      <div className="flex flex-col space-y-2">
        <div className="flex flex-row items-center space-x-4 mt-10">
          {(isCreator && isTimeUp) && (
            <button
              type="submit"
              className="w-[14em] px-8 py-4 bg-black text-white rounded-[50px] font-silka-mono uppercase"
            >
              make it happen
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default IdeaView;
