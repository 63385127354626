import React from "react";
import folder from "../../../images/icones/folder.png";

const ResourceLink = ({
  handleLinkClick,
  isInputVisible,
  link,
  handleInputChange,
}) => {
  return (
    <div className="flex justify-start items-center">
      <img
        onClick={handleLinkClick}
        className="cursor-pointer"
        src={folder}
        alt="folder"
      />
      <p
        onClick={handleLinkClick}
        className="text-sm underline font-silka-mono"
      >
        Share <br />
        resources links
      </p>
      {isInputVisible && (
        <input
          type="text"
          value={link}
          onChange={handleInputChange}
          placeholder="Enter resource link"
          className="ml-12 p-2 border border-black rounded-xl focus:outline-none w-[300px]"
        />
      )}
    </div>
  );
};

export default ResourceLink;
