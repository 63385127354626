import React from "react";

const Button = ({ text, onClick }) => {
  return (
    <div
      onClick={onClick}
      className="justify-center items-center text-md p-4 mt-8 mb-14 cursor-pointer underline"
    >
      {text}
    </div>
  );
};

export default Button;
