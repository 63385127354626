import React, { useEffect, useRef } from "react";
import arrow from "../../../images/icones/arrow.png";
import folder from "../../../images/icones/DraftFolder.png";
import profil from "../../../images/icones/personna.png";
import useStore from "../../../API/store";
import RadioQuestion from "../../../Components/RadioQuestion";
import useRadioQuestion from "../../../Components/Hooks/useRadioQuestion";

const DraftView = ({ handleGoBack, cardDetails, dioData, setSelectedStatus }) => {


  const teamValueOptions = [

    { value: "Minimal", description: "Little to no perceived value", score: 1 },

    {

      value: "Moderate",

      description: "Some perceived value",

      score: 2,

    },

    {

      value: "Significant",

      description: "Noticeable value",

      score: 3,

    },

    {

      value: "Exceptional",

      description: "High perceived value",

      score: 4,

    },

  ];

 

  const complexityOptions = [

    { value: "Simple", description: "Minimal skills needed.", score: 1 },

    { value: "Moderate", description: "Some skill, creativity", score: 2 },

    {

      value: "Complex",

      description: "Advanced skills, creativity",

      score: 3,

    },

    {

      value: "Highly Complex",

      description: "Expert skills, innovation",

      score: 4,

    },

  ];

 

  const frequencyOptions = [

    { value: "Routine", description: "Regular, minimal change", score: 1 },

    {

      value: "Occasionally repeated",

      description: "Sometimes, slight variations",

      score: 2,

    },

    { value: "Rare", description: "Seldom, notable elements", score: 3 },

    { value: "Novel", description: "First time, highest newness", score: 4 },

  ];

  const {
    addAchievement,
    setLoading,
    fetchExecutions,
    setError,
  } = useStore();
  const usersList = [cardDetails?.talent_name, cardDetails?.performor_name];
  const profilePics = [profil, profil];

  const day = cardDetails?.Expected_date?.slice(8, 10);
  const month = cardDetails?.Expected_date?.slice(5, 7);

  const draftViewRef = useRef();

  useEffect(() => {
    if (draftViewRef.current) {
      draftViewRef.current.scrollIntoView({
        block: "start",
        behavior: "instant",
      });
    }
  }, []);

  const title = cardDetails.exec_description;
  const positiveAspect = cardDetails.comment_highlight;
  const date = cardDetails.Expected_date;
  const link = cardDetails.documentation_link;
  const selectedTalent = cardDetails.id_talent_performor;
  const selectedOrganizationId = cardDetails.dioId

  const teamValue = useRadioQuestion(teamValueOptions);
  const complexity = useRadioQuestion(complexityOptions);
  const frequency = useRadioQuestion(frequencyOptions);

  const onSubmit = (e) => {
    e.preventDefault(); // Assurez-vous d'empêcher le comportement par défaut du formulaire
  
    console.log("Form submitted!");
    
    const achievement = {
      title,
      positiveAspect,
      teamValue: teamValue.selectedScore,
      complexity: complexity.selectedScore,
      frequency: frequency.selectedScore,
      date,
      link,
      selectedTalent,
      selectedOrganizationId,
    };
  
    console.log("Achievement data:", achievement);
    setLoading(true)
    addAchievement(achievement)
    .then(() => {
      console.log("Achievement successfully submitted!");
      fetchExecutions(dioData).then(() => {
        console.log("Achievement successfully submitted!");
        setLoading(false);
      });
        handleGoBack();
        setSelectedStatus("all");
      })
      .catch((error) => {
        // Assurez-vous de convertir l'objet Error en message texte
        setError(error.message || "An unexpected error occurred.", "<======Error adding achievement.");
        setLoading(false); // Arrêter le chargement en cas d'erreur
      });
  };
  

  return (
    <div
      ref={draftViewRef}
      className="w-1/2 bg-orange3 p-32 overflow-y-auto max-h-[calc(110vh-64px)]"
    >
      <div className="flex justify-between">
        <img
          src={arrow}
          alt="retour"
          className="w-12 h-12 cursor-pointer"
          onClick={handleGoBack}
        />
        {cardDetails.Expected_date && (
          <div className="text-xs w-[32px] h-[50px] p-1 pl-3 pr-3 mb-6 flex items-center justify-center text-white bg-stone-950 rounded-lg font-silka-mono">
            {day} <br />
            {month}
          </div>
        )}
      </div>
      <p className="font-silka-mono mt-10">Thankward by MKIF</p>
      <p className="font-silka-mono font-semibold text-3xl mt-6">
        {cardDetails?.exec_description}
      </p>

      <div className="flex items-center space-x-2 mt-10">
        {usersList.map((user, index) => (
          <React.Fragment key={index}>
            {profilePics[index] && (
              <img
                src={profilePics[index]}
                alt={user}
                className="w-8 h-8 rounded-full"
              />
            )}
            <p className="text-sm font-silka-mono">{user}</p>
            {index < usersList.length - 1 && <span> &gt; </span>}
          </React.Fragment>
        ))}
      </div>

      {cardDetails?.Instructions && (
        <div className="flex justify-center items-center w-[100px] h-12 border-2 border-black mt-20 rounded-2xl transform -translate-x-4">
          <p className="font-silka-mono font-bold uppercase text-center p-2">
            brief
          </p>
        </div>
      )}
      <p className="font-silka-mono mt-10">{cardDetails?.Instructions}</p>
      {cardDetails?.documentation_link && (
        <a
          href={cardDetails?.documentation_link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="cursor-pointer mt-20 mb-20 -translate-x-8"
            src={folder}
            alt="folder"
          />
        </a>
      )}
              {/* <>
          <RadioQuestion
            label={
              <>
                How do you think most of your <br /> teammates would rate the
                value of this achievement <br />
                for your organization?
              </>
            }
            options={teamValueOptions}
            selectedIndex={teamValue.selectedIndex}
            selectedValue={teamValue.selectedValue}
            selectedScore={teamValue.selectedScore}
            onChange={teamValue.handleChange}
          />

          <RadioQuestion
            label={
              <>
                How complex is this
                <br />
                achievement?
              </>
            }
            options={complexityOptions}
            selectedIndex={complexity.selectedIndex}
            selectedValue={complexity.selectedValue}
            selectedScore={complexity.selectedScore}
            onChange={complexity.handleChange}
          />

          <RadioQuestion
            label={
              <>
                How often has this type
                <br />
                of mission been done?
              </>
            }
            options={frequencyOptions}
            selectedIndex={frequency.selectedIndex}
            selectedValue={frequency.selectedValue}
            selectedScore={frequency.selectedScore}
            onChange={frequency.handleChange}
          />
        </> */}

    <form onSubmit={onSubmit} className="mt-10">
      {cardDetails?.actionText &&  (
        <button
          type="submit"
          className="text-black font-silka-mono uppercase underline transform -translate-x-2 mt-40"
        >
          {cardDetails?.actionText}
        </button>
      )}
      </form>
    </div>
  );
};

export default DraftView;
