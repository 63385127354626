import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import fetchDioData from "./Organisations";

export const TasksContext = createContext();

export const TasksProvider = ({ children }) => {
  const [selectedDioId, setSelectedDioId] = useState(
    localStorage.getItem("DOId"),
  );
  const [dioTasks, setDIOTasks] = useState([]);
  const [dioData, setDioData] = useState([]);

  useEffect(() => {
    // Fetch all DIO data to extract IDs
    fetchDioData(
      setDioData,
      () => {},
      () => {},
    );
  }, []);

  useEffect(() => {
    const fetchAllExecutions = async () => {
      try {
        let allTasks = [];
        for (const dio of dioData) {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/DIO/execution?dioId=${dio.id}`,
          );
          allTasks = [...allTasks, ...response.data.combinedData];
        }
        setDIOTasks(allTasks);
        return; // Arrête l'exécution ici
      } catch (error) {
        console.error("Erreur lors de la récupération des exécutions :", error);
      }
    };

    if (dioData.length > 0) {
      fetchAllExecutions();
    }
  }, [dioData]);

  useEffect(() => {
    const eventSource = new EventSource(
      `${process.env.REACT_APP_BACKEND_URL}/events`,
    );
    eventSource.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.type === "NEW_EXECUTION" || data.type === "UPDATED_EXECUTION") {
        setDIOTasks((prevTasks) => {
          const exists = prevTasks.some((task) => task.id === data.payload.id);
          if (!exists) {
            return [...prevTasks, data.payload];
          }
          return prevTasks;
        });
      }
    };

    return () => {
      eventSource.close();
    };
  }, [setDIOTasks]);

  const addDIOTask = (task) => {
    setDIOTasks((prevTasks) => [...prevTasks, task]);
  };

  const selectDioId = (dioId) => {
    setSelectedDioId(dioId);
  };

  return (
    <TasksContext.Provider
      value={{
        dioTasks,
        addDIOTask,
        selectedDioId, // Include selectedDioId in the context
        selectDioId, // Function to update selectedDioId
      }}
    >
      {children}
    </TasksContext.Provider>
  );
};
