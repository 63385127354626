import React, { useEffect, useRef } from "react";
import Rating from "./Rating";
import arrow from "../../../images/icones/arrow.png";
import folder from "../../../images/icones/Union.png";
import heart from "../../../images/heart2.png";

import profil from "../../../images/icones/personna.png";

const AchivementsView = ({ handleGoBack, selectedCardDetails, thanks }) => {
  const usersList = [selectedCardDetails.talent_name];
  const profilePics = [profil, profil];

  const achievementsViewRef = useRef();

  useEffect(() => {
    if (achievementsViewRef.current) {
      achievementsViewRef.current.scrollIntoView({
        block: "start",
        behavior: "instant",
      });
    }
  }, []);

  const day = selectedCardDetails.creation_date.slice(8, 10);
  const month = selectedCardDetails.creation_date.slice(5, 7);
  console.log(selectedCardDetails?.review_difficulty, "<================= selectedCardDetails review_difficulty");

  
  function aroundNumbers(n) {
    // Remplacer la virgule par un point
    const formattedValue = String(n).replace(",", ".");
    
    // Convertir la chaîne en nombre
    const num = Number(formattedValue);
    
    console.log('Valeur num:', num);  // Vérifie la conversion
  
    // Vérifier si la conversion a échoué
    if (isNaN(num)) {
      return 0;
    }
  
    // Arrondir à l'entier supérieur
    const roundedNum = Math.ceil(num);
    console.log('Valeur arrondie:', roundedNum);  // Vérifie la valeur arrondie
  
    return roundedNum;
  }
  

  return (
    <div
      ref={achievementsViewRef}
      className="w-1/2 bg-gains3 p-32 overflow-y-auto max-h-[calc(110vh-64px)]"
    >
      <div className="flex justify-between">
        <img
          src={arrow}
          alt="retour"
          className="w-12 h-12 cursor-pointer"
          onClick={handleGoBack}
        />
        {selectedCardDetails.Expected_date && (
          <div className=" text-xs w-[32px] h-[50px] p-1 pl-3 pr-3 mb-6 flex items-center justify-center text-white bg-stone-950 rounded-lg font-silka-mono">
            {day} <br />
            {month}
          </div>
        )}
      </div>
      <p className="font-silka-mono mt-10">Thankward by MKIF</p>
      <p className="font-silka-mono font-semibold text-3xl mt-6">
        {selectedCardDetails?.exec_description}
      </p>

      <div className="flex items-center space-x-2 mt-10">
        {usersList.map((user, index) => (
          <React.Fragment key={index}>
            {profilePics[index] && (
              <img
                src={profilePics[index]}
                alt={user}
                className="w-8 h-8 rounded-full"
              />
            )}
            <p className="text-sm font-silka-mono">{user}</p>
            {index < usersList.length - 1 && <span> &gt; </span>}
          </React.Fragment>
        ))}
      </div>
      {selectedCardDetails.Instructions && (
        <div className="flex justify-center items-center w-[100px] h-12 border-2 border-black mt-20 rounded-2xl transform -translate-x-4">
          <p className="font-silka-mono font-bold uppercase text-center p-2">
            brief
          </p>
        </div>
      )}
      <p className="font-silka-mono mt-10">{selectedCardDetails.Instructions}</p>
      {selectedCardDetails.input_link && (
        <a
          href={selectedCardDetails.input_link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="cursor-pointer mt-10 bg-aqua3 -translate-x-8"
            src={folder}
            alt="folder"
          />
        </a>
      )}
      {selectedCardDetails.review_comments && (
        <div className="flex justify-center items-center w-[170px] h-12 border-2 border-black mt-20 rounded-2xl transform -translate-x-4">
          <p className="font-silka-mono font-bold uppercase text-center p-2">
            Liverables
          </p>
        </div>
      )}
      <p className="font-silka-mono mt-10">{selectedCardDetails.review_comments}</p>
      {selectedCardDetails.documentation_link && (
        <a
          href={selectedCardDetails.documentation_link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="cursor-pointer mt-20 -translate-x-8"
            src={folder}
            alt="folder"
          />
        </a>
      )}
      <div className="bg-black p-4 rounded-2xl  mt-20">
        <Rating label="Value" points={4} rating={aroundNumbers(selectedCardDetails.review_bts)} />
        <Rating
          label="Complexity"
          points={4}
          rating={aroundNumbers(selectedCardDetails.review_complexity)}
        />
        <Rating
          label="Novelty"
          points={4}
          rating={aroundNumbers(selectedCardDetails.review_repetition)}
        />
      </div>
      {selectedCardDetails.peer_review_comments && (
        <>
          <div className="flex justify-center items-center w-[140px] h-12 border-2 border-black mt-20 rounded-2xl transform -translate-x-4">
            <p className="font-silka-mono font-bold uppercase text-center p-2">
              Feed-back
            </p>
          </div>

          <div className="relative font-silka-mono p-4 rounded-3xl max-w-xs border-2 border-black mt-10 bg-gains3">
            <p>{selectedCardDetails.peer_review_comments}</p>
            <div className="absolute -bottom-2 left-10 w-0 h-0 border-t-8 border-t-black border-r-8 border-r-transparent border-l-8 border-l-transparent"></div>
          </div>
          <div className="flex items-center space-x-2 mt-10">
            <img src={profil} alt="user" className="w-8 h-8 rounded-full" />

            <p className="text-sm font-silka-mono">
              {selectedCardDetails.peer_review_ids}
            </p>
          </div>
        </>
      )}

      {/* <div className="relative font-silka-mono p-4 rounded-3xl max-w-xs border-2 border-black mt-10 bg-gains3">
        <p>
          Bravo, très belle adaptation des travaux de Drazen Prelec à qui je
          parlerai du modèle Thankward pour avis
        </p>
        <div className="absolute -bottom-2 left-10 w-0 h-0 border-t-8 border-t-black border-r-8 border-r-transparent border-l-8 border-l-transparent"></div>
      </div>

      <div className="flex items-center space-x-2 mt-10">
        <img src={guillaume} alt="Guillaume" className="w-8 h-8 rounded-full" />
        <p className="text-sm font-silka-mono">Guillaume</p>
      </div> */}

{
  (selectedCardDetails?.feedback_bts === null || selectedCardDetails?.feedback_bts === undefined || selectedCardDetails?.feedback_bts === 0 ||
  selectedCardDetails?.peer_review_reactivity === null || selectedCardDetails?.peer_review_reactivity === undefined || selectedCardDetails?.peer_review_reactivity === 0) 
    ? null
    : (
      <div className="bg-black p-4 rounded-2xl mt-20">
        <Rating
          style={{ marginRight: 20 }}
          label="Value"
          points={4}
          rating={aroundNumbers(selectedCardDetails?.feedback_bts)}
        />
        <Rating
          label="Quality"
          points={5}
          rating={aroundNumbers(selectedCardDetails?.peer_review_expectations)}
        />
        <Rating
          label="Reactivity"
          points={5}
          rating={aroundNumbers(selectedCardDetails?.peer_review_reactivity)}
        />
      </div>
    )
}
    </div>
  );
};

export default AchivementsView;
