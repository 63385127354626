import React from "react";
import Confetti from "./Confetti";

export const StatsPanel = ({
  animatedThanks,
  showConfetti,
  celebrate,
  heart,
  coin,
  action,
}) => (
  <div className="flex flex-col items-center bg-black rounded-[32px] shadow-lg p-4 w-20">
    <div className="flex flex-col items-center mb-2 mt-1">
      <img className="w-10 h-10 select-none" alt="Thanks" src={heart} />
      <p className="font-silka-mono text-white text-xs text-center select-none mt-1">
        {animatedThanks} <br />
        <span className="text-slate-400 font-thin text-[10px]">THANKS</span>
      </p>
      {showConfetti && celebrate && <Confetti isActive={showConfetti} />}
    </div>

    {/* <div className="flex flex-col items-center mb-2">
      <img className="w-10 h-10 select-none" alt="Cash" src={coin} />
      <p className="font-silka-mono text-white text-[10px] text-center select-none mt-1 uppercase">
        Not yet <br />
        <span className="text-slate-400 font-thin text-[10px]">CASH</span>
      </p>
    </div>

    <div className="flex flex-col items-center">
      <img className="w-10 h-10 select-none" alt="BCT" src={action} />
      <p className="font-silka-mono text-white text-[10px] text-center select-none mt-1 uppercase">
        Not yet <br />
        <span className="text-slate-400 font-thin text-[10px]">BCT</span>
      </p>
    </div> */}
  </div>
);
