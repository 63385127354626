import React from "react";
import heart from "../../../images/heart2.png";
import ideaIcon from "../../../images/icones/ideaLamp.svg"
import IdeaCountdown from "../../../Components/IdeaCountdown.js";

const Card = ({
  date,
  title,
  users,
  thanks,
  actionText,
  organisation,
  bgColor,
  isSplitView,
  profilePics,
  id,
  status,
  creation_date,
}) => {
  
  const usersList = users?.split(" > ");

  const getDateByStatus = (status, date) => {
      if (status === "In review") {
        return "3 J";
    } else if (status === "On going idea") {
        return "21 J";
    } else {
        return date
    }
  }

  return id !== 3 ? (
    <div
      className={`${isSplitView ? "w-[200px] h-[300px] pt-[30px] pl-[25px] pr-[25px]" : "w-[280px] h-[380px] pt-[40px] pl-[32px] pr-[32px]"} ${status === "Draft idea" ? "bg-orange3" : bgColor}  rounded-[2rem] cursor-pointer`}
    >
      <div className="flex justify-between">
      {(status === "On going idea" || status === "Achieved idea") && (
        <IdeaCountdown creationDate={creation_date} isSplitView={isSplitView} displayMode="compact" />
      )}
        {status !== "Achieved" && date && (
          <div
            className={`${isSplitView ? "text-[10px] w-[26px] h-[42px] pl-1 pr-1 mb-2" : "text-xs w-[32px] h-[50px] p-1 pl-3 pr-3 mb-6"} flex items-center justify-center  text-white  bg-stone-950 rounded-lg`}
          >
             {getDateByStatus(status, date)}
          </div>
        )}
        {status !== "Achieved" && !date && (
          <div
            className={`${isSplitView ? "text-[10px] w-[26px] h-[42px] pl-1 pr-1 mb-2" : "text-xs w-[32px] h-[50px] p-1 pl-3 pr-3 mb-6"} flex items-center justify-center  text-white  bg-${bgColor} rounded-lg`}
          ></div>
        )}
        {status !== "Achieved" && status !== "Open idea"  && status !== "On going idea"  && (
          <div>
            {status === "Achieved idea" || status === "Draft idea" ? (
              <div className={`${isSplitView ? "max-h-10":"max-h-12"}  inline-flex items-center justify-center rounded-lg p-1 pt-2 pb-2  bg-black`}>
                <img
                  alt="ideaIcon"
                  className={`${isSplitView ? "w-4 h-4" : "w-8 h-8"}`}
                  src={ideaIcon}
                />
              </div>
            ) : (
              <img
                alt="thanks"
                className={`${isSplitView ? "w-4 h-4" : "w-6 h-6"}`}
                src={heart}
              />
            )}
            {/* <div className={`${isSplitView ? 'text-[10px]' : 'text-xs' } text-center  text-gray-500  mt-2`}>{thanks}</div> */}
          </div>
        )}
        {status === "On going idea" && (
          <div className={`${isSplitView ? "max-h-10":"max-h-12"}  inline-flex items-center justify-center rounded-lg p-1  bg-black`}>
            <img
              alt="ideaIcon"
              className={`${isSplitView ? "w-4 h-4" : "w-8 h-8"}`}
              src={ideaIcon}
            />
          </div>
        )}
            
      </div>
      {(status === "Achieved" || status === "Achieved idea") && (
        <div
          className={`${isSplitView ? "w-[102px] h-[56px]" : "w-[140px] h-[70px]"} flex items-center justify-center bg-stone-950 rounded-[12px]  mx-auto my-2`}
        >
          <p
            className={`${isSplitView ? "text-2xl" : "text-3xl"} text-gains3  text-center font-silka-mono `}
          >
            {thanks}
          </p>
          <img
            className={`${isSplitView ? "w-8 h-8 p-1" : "w-9 h-9 p-1"} `}
            alt="Thanks"
            src={heart}
          />
        </div>
      )}

      <h3
        className={`${isSplitView ? "text-[8px] pt-4" : "text-xs pt-6"} font-silka-mono`}
      >
        {organisation}
      </h3>
      <p
        className={`${isSplitView ? "text-xs" : "text-lg"} font-silka-mono  font-semibold pt-4 overflow-hidden`}
        style={{
          display: "-webkit-box",
          WebkitLineClamp: 3,
          WebkitBoxOrient: "vertical",
          height: "5.5rem",
          lineHeight: "1.5rem",
        }}
      >
        {title}
      </p>

      <div className="flex items-center space-x-2 mt-2">
        {usersList?.map((user, index) => (
          <React.Fragment key={index}>
            {profilePics[index] && (
              <img
                src={profilePics[index]}
                alt={user}
                className={`${isSplitView ? "w-6 h-6" : "w-8 h-8"} rounded-full`}
              />
            )}
            <p
              className={`${isSplitView ? "text-[8px]" : "text-[10px]"} font-silka-mono`}
            >
              {user}
            </p>
            {index < usersList.length - 1 && <span> &gt; </span>}
          </React.Fragment>
        ))}
      </div>

      <p
        className={`${isSplitView ? "text-xs mt-7" : "text-sm mt-8"} font-silka-mono underline`}
      >
        {status === "Draft idea" ? "SHARE YOUR IDEA" : status === "On going idea" ? "My Feedback" : actionText}
      </p>

    </div>
  ) : (
    <div
      className={`${isSplitView ? "w-[200px] h-[300px] pt-[30px] pl-[25px] pr-[25px]" : "w-[260px] h-[360px] pt-[40px] pl-[32px] pr-[32px]"} ${bgColor}  rounded-[2rem] cursor-pointer`}
    >
      <div className="flex justify-center items-center">
        <div className="bg-gains3">
          <div
            className={`${isSplitView ? "w-[112px] h-[56px]" : "w-[132px] h-[66px]"} flex items-center justify-center bg-stone-950 rounded-[12px]  mx-auto my-2`}
          >
            <p
              className={`${isSplitView ? "text-3xl" : "text-4xl"} text-gains3  text-center font-silka-mono`}
            >
              {thanks}
            </p>
            <img
              className={`${isSplitView ? "w-8 h-8 p-1" : "w-10 h-10 p-1"}`}
              alt="Thanks"
              src={heart}
            />
          </div>
          <h3
            className={`${isSplitView ? "text-[8px] pt-4" : "text-xs pt-6"} font-silka-mono`}
          >
            {organisation}
          </h3>
          <p
            className={`${isSplitView ? "text-xs" : "text-md"} font-silka-mono  font-semibold pt-4 overflow-hidden`}
            style={{
              display: "-webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
              height: "5.5rem",
              lineHeight: "1.5rem",
            }}
          >
            {title}
          </p>

          <div className="flex items-center space-x-2 mt-2">
            {usersList.map((user, index) => (
              <React.Fragment key={index}>
                {profilePics[index] && (
                  <img
                    src={profilePics[index]}
                    alt={user}
                    className={`${isSplitView ? "w-6 h-6" : "w-8 h-8"} rounded-full`}
                  />
                )}
                <p
                  className={`${isSplitView ? "text-[8px]" : "text-[10px]"} font-silka-mono`}
                >
                  {user}
                </p>
                {index < usersList.length - 1 && <span> &gt; </span>}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
