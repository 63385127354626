import React from 'react';

const ProjectCard = ({ number, organization, title }) => {
  return (
    <button className="rounded-lg p-6 border-2 border-black flex flex-col gap-2">
      <div className="bg-black text-white w-8 h-8 rounded-full flex items-center justify-center text-sm">
        {number}
      </div>
      <div className="text-sm uppercase">{organization}</div>
      <div className="font-mono font-bold">{title}</div>
    </button>
  );
};

export default ProjectCard;