import React from "react";
import esc from "../../../images/icones/esc.png";




const PlusButton =({clicked,handleClick })=> {


return (
  <button
    onClick={handleClick}
    className={`flex items-center justify-center w-[58px] h-[58px] bg-black rounded-full shadow-lg hover:bg-gray-900 transition-colors m-4 duration-200 mx-auto mb-4 hover:opacity-90 ${
      clicked ? "rotate-fast" : "rotate-slow"
    }`}
  >
    {clicked ? (
      <img className="w-8 h-8 select-none" alt="exit" src={esc} />
    ) : (
      <span className="text-white text-center justify-center text-3xl font-extralight select-none pl-[0.1px] pb-[1px]">+</span>
    )}
  </button>
);
}

export default PlusButton
