// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.plus-card{
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
}  

.review-card{
    margin-left: 73.5%;
    font-size: 70%;
    background-color:  #00000026; 
    border-radius: 2vh;
    border: 0.5vh solid transparent;
    justify-content: center;
    align-items: center;
    margin-top: 5vh;
    color: rgb(0, 0, 0);
    padding: 2%;
}
.review-card:hover{
    background-color:  transparent;
    cursor: pointer;
}

`, "",{"version":3,"sources":["webpack://./src/Components/ExecutionsCards/ExecutionCardFooter/ExecutionCardFooter.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,6BAA6B;IAC7B,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,4BAA4B;IAC5B,kBAAkB;IAClB,+BAA+B;IAC/B,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,mBAAmB;IACnB,WAAW;AACf;AACA;IACI,8BAA8B;IAC9B,eAAe;AACnB","sourcesContent":[".plus-card{\n    background-color: transparent;\n    border: 1px solid transparent;\n    cursor: pointer;\n}  \n\n.review-card{\n    margin-left: 73.5%;\n    font-size: 70%;\n    background-color:  #00000026; \n    border-radius: 2vh;\n    border: 0.5vh solid transparent;\n    justify-content: center;\n    align-items: center;\n    margin-top: 5vh;\n    color: rgb(0, 0, 0);\n    padding: 2%;\n}\n.review-card:hover{\n    background-color:  transparent;\n    cursor: pointer;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
