import { useState } from "react";

const useRadioQuestion = (initialOptions, initialIndex = 0) => {
  const [selectedIndex, setSelectedIndex] = useState(initialIndex);
  const selectedValue = initialOptions[selectedIndex].value;
  const selectedScore = initialOptions[selectedIndex].score;

  const handleChange = (index) => {
    setSelectedIndex(index);
  };

  return { selectedScore, selectedIndex, selectedValue, handleChange };
};

export default useRadioQuestion;
