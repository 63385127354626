// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.message-bubble {
    background-color: #5CB7BB66;
    border-radius: 15px;
    margin-bottom: 0vh;
    margin-top: 1.6vh;
    padding: 15px;
    color: rgb(0, 0, 0);
    font-family: 'Kumbh Sans', sans-serif;
    font-size: 1.3vw ;
    flex-direction: column;
}


.method{
    font-size: 80%;
    background-color:  #474E5426; 
    border-radius: 15px;
    margin-bottom: 1vh;
    padding: 2%; 
}


.right-second{
    display: flex;
    font-size: 90%;
    width: 100%;
}

.meet-dead {
    font-size: 90%;
    background-color: #474E5426;
    border-radius: 15px;
    margin-bottom: 1vh;
    margin-left: 2%;
    padding: 2%;
}

.peer-review {
    font-size: 70%;
    background-color: #474E5426;
    border-radius: 15px;
    margin-bottom: 1vh;
    margin-top: 0vh;
    padding: 2%;
    color: black;
    font-family: 'Kumbh Sans', sans-serif;
    width: 40vw;
    margin-left: 0%;
}
`, "",{"version":3,"sources":["webpack://./src/Components/ExecutionsCards/ExecutionCard.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,mBAAmB;IACnB,kBAAkB;IAClB,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,qCAAqC;IACrC,iBAAiB;IACjB,sBAAsB;AAC1B;;;AAGA;IACI,cAAc;IACd,4BAA4B;IAC5B,mBAAmB;IACnB,kBAAkB;IAClB,WAAW;AACf;;;AAGA;IACI,aAAa;IACb,cAAc;IACd,WAAW;AACf;;AAEA;IACI,cAAc;IACd,2BAA2B;IAC3B,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;IACf,WAAW;AACf;;AAEA;IACI,cAAc;IACd,2BAA2B;IAC3B,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;IACf,WAAW;IACX,YAAY;IACZ,qCAAqC;IACrC,WAAW;IACX,eAAe;AACnB","sourcesContent":[".message-bubble {\n    background-color: #5CB7BB66;\n    border-radius: 15px;\n    margin-bottom: 0vh;\n    margin-top: 1.6vh;\n    padding: 15px;\n    color: rgb(0, 0, 0);\n    font-family: 'Kumbh Sans', sans-serif;\n    font-size: 1.3vw ;\n    flex-direction: column;\n}\n\n\n.method{\n    font-size: 80%;\n    background-color:  #474E5426; \n    border-radius: 15px;\n    margin-bottom: 1vh;\n    padding: 2%; \n}\n\n\n.right-second{\n    display: flex;\n    font-size: 90%;\n    width: 100%;\n}\n\n.meet-dead {\n    font-size: 90%;\n    background-color: #474E5426;\n    border-radius: 15px;\n    margin-bottom: 1vh;\n    margin-left: 2%;\n    padding: 2%;\n}\n\n.peer-review {\n    font-size: 70%;\n    background-color: #474E5426;\n    border-radius: 15px;\n    margin-bottom: 1vh;\n    margin-top: 0vh;\n    padding: 2%;\n    color: black;\n    font-family: 'Kumbh Sans', sans-serif;\n    width: 40vw;\n    margin-left: 0%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
