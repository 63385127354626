import React, { useState } from "react";

import arrow from "../../../images/icones/arrow.png";
import logo from "../../../images/logo.png";
import invite from "../../../images/icones/invite.svg";
import useStore from "./../../../API/store";

const CooptationForm = ({ handleGoBack, dioData, setSelectedStatus,setPasswordModal }) => {
  const {
    setLoading,
    fetchIdeas,
    setError,
    cooptTalent
  } = useStore();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });

  const [selectedOrg, setSelectedOrg] = useState(null);
  const [creatOrg, setCreatOrg] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      alert("Please fill all required fields.");
      return;
    }

    try {
      const selectedDio = dioData.find(dio => dio.name === selectedOrg);
      if (!selectedDio && selectedOrg !== "Sponsoring") {
        throw new Error("Please select a valid Collab Space");
      }

      const dioId = selectedOrg === "Sponsoring" ? "sponsoring" : selectedDio.id;

      await cooptTalent(
        formData.firstName,
        formData.lastName,
        formData.email,
        formData.message,
        dioId
      );

      console.log("Cooptation successful!");
      setPasswordModal(true);
      handleGoBack();
      setSelectedStatus("all");
    } catch (error) {
      setError(error.message);
      console.error("Cooptation error:", error);
    }
  };
  

  const handleSaveForLater = () => {
    console.log("Saved for later:", { organization: selectedOrg, ...formData });
  };

  console.log(selectedOrg, "<======= selectedOrg");
  // Fonction pour vérifier si l'email est valide
const validateEmail = (email) => {
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailPattern.test(email);
};
  const validateForm = () => {
    // Vérifier que le prénom, nom, email, et message ne sont pas vides
    const isFormValid =
      formData.firstName.trim() !== "" && // Le prénom ne doit pas être vide
      formData.lastName.trim() !== "" && // Le nom ne doit pas être vide
      formData.email.trim() !== "" && // L'email ne doit pas être vide
      formData.message.trim() !== "" && // Le message ne doit pas être vide
      selectedOrg !== null && // Une organisation doit être sélectionnée
      validateEmail(formData.email); // Vérifier que l'email est valide
  
    return isFormValid;
  };

  return (
    <div className="w-1/2 bg-rightGreyBg p-32 overflow-y-auto max-h-[calc(110vh-64px)]">
      <div className="mb-8">
        <img
          src={arrow}
          alt="retour"
          className="w-12 h-12 cursor-pointer mb-10"
          onClick={handleGoBack}
        />
        <div className="text-xs font-silka-mono uppercase tracking-wide mb-5">
          FORM - COOPTATION
        </div>
        <h1 className="text-4xl font-silka-mono font-bold mb-6">
          Invite a Talent
        </h1>
      </div>

      <div className="mb-12">
        <h2 className="text-lg mb-4 font-silka-mono font-semibold">
          Choose a <br /> Collab Space{" "}
        </h2>
        <div className="grid grid-cols-3 gap-4 max-w-[90%]">
          <button
            key="create-new-organization"
            className={`p-2 border-2 rounded-xl flex flex-col items-center justify-between text-start aspect-square ${
              selectedOrg === "Sponsoring" ? "border-black" : "border-gray-400"
            }`}
            onClick={() => setSelectedOrg("Sponsoring")}
          >
            <div className="w-full flex justify-end">
              <img
                src={invite}
                alt="invite icon"
                className="w-6 h-auto extraLarge:w-8 twoXl:w-14"
              />
            </div>
            <div className="w-full text-left">
              <span className="font-silka-mono font-semibold text-xs leading-tight underline extraLarge:text-[] twoXl:text-lg">
                <p className="leading-tight">+ Invitation</p>
                <p className="leading-tight">for the</p>
                <p className="leading-tight">creator of</p>
                <p className="leading-tight">a Collab Space</p>
              </span>
            </div>
          </button>
          {dioData.map((item, index) => (
            <button
              key={item.id || index}
              className={`p-4 border-2 rounded-lg flex flex-col items-center justify-center aspect-square ${
                selectedOrg === item.name
                  ? "border-black font-semibold"
                  : "border-gray-400"
              }`}
              onClick={() => setSelectedOrg(item.name)}
            >
              <img
                src={logo}
                alt="retour"
                className="max-w-10 max-h-10 cursor-pointer mb-4"
              />
              <span className="font-silka-mono text-xs">{item.name}</span>
            </button>
          ))}
        </div>
      </div>

      <div className="mb-8">
        <h2 className="text-lg mb-4 font-silka-mono font-semibold">
          Who do you want <br />
          to invite ?
        </h2>
        <form onSubmit={handleSubmit} className="space-y-4 max-w-[80%]">
          <input
            type="text"
            placeholder="FIRST NAME"
            className="w-full p-4 border-2 border-gray-700 rounded-lg focus:border-black focus:ring-0 focus:outline-none bg-rightGreyBg font-silka-mono"
            value={formData.firstName}
            onChange={(e) =>
              setFormData({ ...formData, firstName: e.target.value })
            }
          />

          <input
            type="text"
            placeholder="LASTE NAME"
            className="w-full p-4 border-2 border-gray-700 rounded-lg focus:border-black focus:ring-0 focus:outline-none bg-rightGreyBg font-silka-mono"
            value={formData.lastName}
            onChange={(e) =>
              setFormData({ ...formData, lastName: e.target.value })
            }
          />

          <input
            type="email"
            placeholder="EMAIL"
            className="w-full p-4 border-2 border-gray-700 rounded-lg focus:border-black focus:ring-0 focus:outline-none bg-rightGreyBg font-silka-mono"
            value={formData.email}
            onChange={(e) =>
              setFormData({ ...formData, email: e.target.value })
            }
          />

          <textarea
            placeholder="VOTRE MESSAGE"
            className="w-full p-4 border-2 border-gray-700 rounded-lg h-40 focus:border-black focus:ring-0 focus:outline-none bg-rightGreyBg font-silka-mono"
            value={formData.message}
            onChange={(e) =>
              setFormData({ ...formData, message: e.target.value })
            }
          />

          <div className="flex gap-4">
            <button
              type="submit"
              className="flex-1 bg-black text-white text-sm py-3 px-6 rounded-full hover:bg-gray-800 transition-colors font-silka-mono max-w-[40%] h-[50px]"
            >
              SEND
            </button>

            {/* <button
              type="button"
              onClick={handleSaveForLater}
              className="flex-1 border-2 border-black text-sm py-3 px-6 rounded-full hover:bg-gray-100 transition-colors font-silka-mono outline-none h-[50px] text-center"
            >
              SAVE FOR LATER
            </button> */}
          </div>
        </form>
      </div>
    </div>
  );
};

export default CooptationForm;
