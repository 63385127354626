import React from "react";

const OrganizationType = ({ selected, onSelect }) => {
  const types = ["FOR PROFIT", "NON PROFIT", "ACADEMIC", "LIFESTYLE"];

  return (
    <div>
      <p className="mb-4 font-silka-mono">
        Choose the nature of your organization
      </p>
      <div className="flex flex-wrap gap-4 font-silka-mono">
        {types.map((type) => (
          <button
            key={type}
            type="button"
            className={`px-6 py-3 rounded-lg border-2 border-black ${
              selected === type ? "bg-black text-white" : "bg-transparent"
            }`}
            onClick={() => onSelect(type)}
          >
            {type}
          </button>
        ))}
      </div>
    </div>
  );
};

export default OrganizationType;
