

import ExecutionBoard from "./ExecutionBoard";




const DIOhomepage = () => {
  return ( <ExecutionBoard page={"Feed"} />);
}



export default DIOhomepage;
