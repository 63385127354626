import React from 'react';

const FilterButton = ({ children, count, active, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={`px-6 py-3 rounded-full border-2 border-black font-mono flex items-center gap-2 ${
        active ? 'bg-black text-white' : 'bg-rightGreyBg text-black'
      }`}
    >
      {children}
      {count && (
        <span className={`w-6 h-6 rounded-full flex items-center justify-center text-sm ${
          active ? 'bg-white text-black' : 'bg-black text-white'
        }`}>
          {count}
        </span>
      )}
    </button>
  );
};

export default FilterButton;