// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from "./pages/HomePage/Homepage";
import Welcome from "./pages/Coopt-Sponsorize-CreateDO/Welcome";
import LoginPage from "./pages/Profile-Help-Login/login";
import "./index.css";

import { TasksProvider } from "./API/Executions.js";

export default function App() {
  return (
    <TasksProvider>
      <BrowserRouter>
        <Routes>
          <Route index element={<LoginPage />} />
          <Route path="Welcome" element={<Welcome />} />
          <Route path="Homepage" element={<Homepage />} />
        </Routes>
      </BrowserRouter>
    </TasksProvider>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
