import React, { useEffect, useState, useRef } from "react";
import Header from "./Components/Header";
import FilterStatusCards from "./Components/FilterStatusCards";
import FixedSidebar from "./Components/FixedSidebar";
import CardsList from "./Components/CardsList";

import Modal from "./Components/Modals/CollectModal";
import IdeaModal from "./Components/IdeaModal";
import CooptModal from "./Components/Modals/CooptModal";
import PasswordModal from "./Components/Modals/PasswordModal";

import Button from "../../Components/Button";
import fetchDioData from "../../API/Organisations";

import AchivementsView from "./Components/AchivementsView";
import FeedbackView from "./Components/FeedbackView";
import IdeaFeedbackView from "./Components/IdeaFeedbackView"
import NewAchievementsView from "./Components/NewAchivementsView";
import OportunityView from "./Components/OportunityView";
import DraftView from "./Components/DraftView";
import NewIdeaView from "./NewIdeaView";
import NewTalentHub from "./NewTalentHub";
import FilterView from "./Components/FilterView/FilterView";

import IdeaView from "./Components/IdeaView";
import CooptView from "./Components/CooptView";

import FakeComponent from "./FakeComponent";

import useStore from "../../API/store";
import EditIdeaView from './Components/EditIdeaView';


const Homepage = () => {
  const {
    dioData,
    setDioData,
    dioTasks,
    ideas,
    loading,
    error,
    setLoading,
    setError,
    fetchExecutions,
    fetchIdeas,
    combinedTasksAndIdeas,
    setCombinedTasksAndIdeas,
    setClicked,
    clicked,
    filter,
    setFilter,
    setOnGoingCount,
    setOpenCount,
    setInReviewCount,
    setAchievedCount
  } = useStore();

  const [showModal, setShowModal] = useState(false);
  const [showCooptModal, setShowCooptModal] = useState(false);
  const [passwordModal, setPasswordModal] =useState(false);
  const [totalThanks, setTotalThanks] = useState(0);
  const [selectedActionText, setSelectedActionText] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [isSplitView, setIsSplitView] = useState(false);
  const [selectedCardIndex, setSelectedCardIndex] = useState(null);
  const [showIdeaModal, setShowIdeaModal] = useState(null);
  const [isCreating, setIsCreating] = useState(false);
  const [creationType, setCreationType] = useState(null);
  const [openIdea, setOpenIdea] = useState(false);
  const [celebrate, setCelebrate] = useState(false);
  const [openNewIdeaView, setOpenNewIdeaView] = useState(false);
  const [status, setStatus] = useState("all");

  const [onInvite, setOnInvite] = useState(false);

  const FilterStatusCardsRef = useRef(null);
  const timerRef = useRef(null);
  
  useEffect(() => {
    if (dioTasks.length > 0 && ideas.length > 0) {
      const formattedIdeas = ideas.map((idea) => ({
        id: idea?.id,
        exec_description: idea?.title,
        comment_highlight: idea?.description,
        performor_name: idea?.membre_name,
        membre_first_name: idea?.membre_first_name,
        status_: idea?.status_,
        creation_date: idea?.creation_date ? new Date(idea?.creation_date) : null,
        expected_date: idea?.expectedDate ? new Date(idea?.expectedDate) : null,
        comment_improvement: idea?.importance,
        id_talent_creator: idea?.id_creator,
        yesCount: idea?.yesCount,
        noCount: idea?.noCount,
        feedbacks: idea?.feedbacks?.map((feedback) => ({
          name :feedback?.contributor?.name,
          first_name: feedback?.contributor?.first_name,
          feeling_idea: feedback?.feeling_idea || null,
          contribution: feedback?.contribution || null,
          vote: feedback?.vote || null,
        })) || [],
      }));
      
      // Combine les dioTasks et formattedIdeas
      const combined = [...dioTasks, ...formattedIdeas];
      // Trier par date de création
      const sortedCombined = combined.sort((a, b) => new Date(b.creation_date).getTime() - new Date(a.creation_date).getTime());
      setCombinedTasksAndIdeas(sortedCombined);
    }
  }, [dioTasks, ideas, setCombinedTasksAndIdeas]);  
  
  const calculateStatusCounts = React.useCallback((tasksAndIdeas) => {
    let ongoing = 0;
    let open = 0;
    let inReview = 0;
    let achieved = 0;
  
    tasksAndIdeas.forEach((item) => {
      const statusToCheck = item.displayStatus || item.status_;
      
      switch (statusToCheck) {
        case "On going":
          ongoing++;
          break;
        case "Open":
        case "Open idea":
          open++;
          break;
        case "In review":
        case "On going idea":
          inReview++;
          break;
        case "Achieved":
        case "Achieved idea":
          achieved++;
          break;
        default:
          break;
      }
    });
  
    setOnGoingCount(ongoing);
    setOpenCount(open);
    setInReviewCount(inReview);
    setAchievedCount(achieved);
  }, [setOnGoingCount, setOpenCount, setInReviewCount, setAchievedCount]);

  useEffect(() => {
    fetchDioData(setDioData, setLoading, setError);
  }, []);

  const closeModal = () => {
    setShowCooptModal(false);
  };

  const closeCooptModal = () => {
    setShowCooptModal(false);
  };

  const closeIdeaModal = () => {
    setShowIdeaModal(false);
  };

  const handleRecover = (heartCount) => {
    setTotalThanks((prevTotalThanks) => prevTotalThanks + heartCount);
    setCelebrate(true);
    setTimeout(() => setCelebrate(false), 1000);
  };

  const onClickFilterButton = () => {
    setSelectedStatus("all");
    setStatus("all");
  };

  const HandleFilter = () =>{
    !filter &&
    setFilter(!filter)
    setIsSplitView(true) 
  }

  const handleCardClick = (status) => {
    if (selectedStatus === status) {
      setSelectedStatus(status);
    } else {
      setSelectedStatus(status);
    }
  };

  const handleCardFeedClick = (id) => {
    setSelectedCardIndex(id);
    setIsSplitView(true);
  };

  const handleGoBack = () => {
    setIsSplitView(false);
    setStatus("all");
    setIsCreating(false);
    setOnInvite(false);
    setFilter(false)
    setClicked(false);
    clearTimeout(timerRef.current);
  };

  const selectedCardDetails = combinedTasksAndIdeas.find(
    (card) => Number(card.id) === Number(selectedCardIndex),
  );


  console.log(selectedCardDetails, "<======== selectedCardDetails");
  console.log(ideas,"<============= ideas");
  

  useEffect(() => {
    if (isSplitView) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isSplitView]);

  const userName = localStorage.getItem("userName");

  useEffect(() => {
    if (dioData.length > 0) {
      fetchExecutions(dioData); // Fetch les exécutions chaque fois que dioData est chargé
    }
  }, [dioData, fetchExecutions]);

  useEffect(() => {
    const loadIdeas = async () => {
      try {
        await fetchIdeas(dioData); // Passer dioData à la méthode
      } catch (error) {
        console.error(error);
      }
    };
  
    loadIdeas();
  }, [dioData, fetchIdeas]);

  useEffect(() => {
    if (combinedTasksAndIdeas.length > 0) {
      calculateStatusCounts(combinedTasksAndIdeas);
    }
  }, [combinedTasksAndIdeas, calculateStatusCounts]);
  
  return (
    <div
      className={`w-full mx-auto relative ${isSplitView ? "max-w-screen-3xl flex" : "max-w-screen-xl"}`}
    >
      <div
        className={`${isSplitView ? "w-1/2 overflow-y-auto max-h-screen" : "w-full"}`}
      >
        <Header />
        {error && <p className="text-center text-red-500">{error}</p>}

        {loading ? (
             <div className="flex items-center justify-center h-full">
             <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-black-500 border-opacity-75"/>
           </div>
        ) : !dioData && !loading ? (
          <h2 className="text-center text-xl">Aucune donnée trouvée</h2>
        ) : (
          <>
            {!isSplitView && (
              <FixedSidebar
                className="absolute top-0 right-0"
                insideDO={false}
                totalThanks={totalThanks}
                setIsCreating={setIsCreating}
                setCreationType={setCreationType}
                setIsSplitView={setIsSplitView}
                celebrate={celebrate}
                timerRef={timerRef}
              />
            )}

{passwordModal &&<PasswordModal setPasswordModal={setPasswordModal} closeModal={closeModal}/>}

            <FilterStatusCards
              handleCardClick={handleCardClick}
              isSplitView={isSplitView}
              setStatus={setStatus}
              ref={FilterStatusCardsRef}
            />

            <div className="flex justify-center items-center mb-8 font-silka-mono">
            <Button
                onClick={HandleFilter}
                text={
                  status === "all" ? (
                    <span>
                      Filter
                    </span>
                  ) : status === "In review" ? (
                    "Feedback"
                  ) : status === "Open" ? (
                    "Opportunities"
                  ) : (
                    status
                  )
                }
              />

              {status !== "all" && selectedStatus !== "all" && (
                <button
                  onClick={onClickFilterButton}
                  className="ml-1 p-2 rounded-full hover:bg-black hover:text-white flex items-center justify-center mb-6 group"
                  aria-label="Reset Status"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-gray-500 group-hover:text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              )}
            </div>

            <div className="flex justify-around">
              <CardsList
                date={"date"}
                dioData={dioData}
                users={"users"}
                thanks={"150"}
                organisation={dioData}
                isSplitView={isSplitView}
                selectedCardIndex={selectedCardIndex}
                onCardClick={(id) => handleCardFeedClick(id)}
                status={status}
                selectedStatus={selectedStatus}
                loading={loading}
              />
            </div>
          </>
        )}
        {dioData.length === 0 && (
          <div className="flex items-center justify-center h-full">
            <div className="animate-spin rounded-full h-20 w-20 border-t-4 border-black border-opacity-75"/>
          </div>

        )}
      </div>

      {isSplitView &&
        !isCreating &&
        (selectedCardDetails?.status_ === "Achieved" && !filter && (
          <FakeComponent
            handleGoBack={handleGoBack}
          />
        ))}

      {isSplitView &&
        !isCreating &&
        
        selectedCardDetails?.status_ === "In review" &&  !filter &&(
          <FeedbackView
          selectedCardDetails={selectedCardDetails}
            handleGoBack={handleGoBack}
            dioData={dioData}
            userName={userName}
            setSelectedStatus={setSelectedStatus}
          />
        )}

      {isSplitView &&
        !isCreating &&
        selectedCardDetails?.status_ === "Open" && !filter &&(
          <OportunityView
            dioData={dioData}
            handleGoBack={handleGoBack}
            cardDetails={selectedCardDetails}
          />
        )}

      {isSplitView && isCreating && creationType === "achievements" && (
        <NewAchievementsView
          dioData={dioData}
          handleGoBack={handleGoBack}
          setIsSplitView={setIsSplitView}
          setSelectedStatus={setSelectedStatus}
        />
      )}

      {isSplitView && isCreating && creationType === "idea" && (
        <NewIdeaView dioData={dioData} handleGoBack={handleGoBack} setSelectedStatus={setSelectedStatus}/>
      )}

      {isSplitView &&
        !isCreating &&
        selectedCardDetails?.status_ === "On going" &&
        !openIdea &&
        !openNewIdeaView &&
        !onInvite && (
          <DraftView
            dioData={dioData}
            handleGoBack={handleGoBack}
            cardDetails={selectedCardDetails}
          />
        )}

      {isSplitView && !isCreating && !filter && (
        selectedCardDetails?.status_ === "Draft idea" ? (
          <EditIdeaView
            handleGoBack={handleGoBack}
            ideaData={selectedCardDetails}
            dioData={dioData}
          />
        ) : selectedCardDetails?.displayStatus === "Achieved idea" || selectedCardDetails?.status_ === "Achieved idea" ? (
          <IdeaView
            handleGoBack={handleGoBack}
            cardDetails={selectedCardDetails}
            dioData={dioData}
          />
        ) : (selectedCardDetails?.status_ === "On going idea" && selectedCardDetails?.displayStatus !== "Achieved idea") ? (
          <IdeaFeedbackView
            selectedCardDetails={selectedCardDetails}
            handleGoBack={handleGoBack}
            dioData={dioData}
            userName={userName}
            setSelectedStatus={setSelectedStatus}
          />
        ) : null
      )}

      {((onInvite && isSplitView) ||
        (isSplitView && isCreating && creationType === "cooptation")) && (
        <CooptView
          handleGoBack={handleGoBack}
          cardDetails={selectedCardDetails}
          dioData={dioData}
          setSelectedStatus={setSelectedStatus}
          setPasswordModal={setPasswordModal}
        />
      )}

      {showCooptModal && (
            <CooptModal
              setShowCooptModal={setShowCooptModal}
              setIsSplitView={setIsSplitView}
              setOnInvite={setOnInvite}
              onInvite={onInvite}
              closeModal={closeModal}
              setIsCreating={setIsCreating}
              setCreationType={setCreationType}
            />
          )}

      {isSplitView && isCreating && creationType === "talentHub" &&(
        <NewTalentHub handleGoBack={handleGoBack} setSelectedStatus={setSelectedStatus} setShowCooptModal={setShowCooptModal} setOnInvite={setOnInvite}/>
      )}

      {isSplitView && filter && !isCreating && <FilterView handleGoBack={handleGoBack} handleCardClick={handleCardClick} selectedStatus={selectedStatus}/> }
    </div>
  );
};

export default Homepage;
