// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loginA{
    background-color:  #B7D6ED;
    font-family: "Kumbh Sans";
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    display: 'flex';
    flex-direction: 'column';
}

.loginB{

    width: 100%;
    height: 95vh;
}


.logo{
    width: 100%;
    height: 40%;
    justify-content: center;
    margin-top: 10%;
}

.login{
    background-color:  white;
    width: 18%;
    height: 8%;
    justify-content: center;
    align-items: center;
    color: black;
    margin-left: 40%;
    border-radius: 20px;
    font-family: "Kumbh Sans";
}

.login:hover{
    background-color:  gray;
}

.deconnexion{
    background-color:  white;
    width: 25%;
    height: 20%;
    justify-content: center;
    align-items: center;
    color: black;
    margin-left: 0%;
    border-radius: 20px;
    font-family: "Kumbh Sans";
    margin-top: 60%;
    padding: 5%;
    display: flex;
    border: 1px solid transparent;
    cursor: pointer;
}

.deconnexion:hover{
    background-color:  gray;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Profile-Help-Login/Myprofile.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,yBAAyB;IACzB,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,wBAAwB;AAC5B;;AAEA;;IAEI,WAAW;IACX,YAAY;AAChB;;;AAGA;IACI,WAAW;IACX,WAAW;IACX,uBAAuB;IACvB,eAAe;AACnB;;AAEA;IACI,wBAAwB;IACxB,UAAU;IACV,UAAU;IACV,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,gBAAgB;IAChB,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,wBAAwB;IACxB,UAAU;IACV,WAAW;IACX,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,eAAe;IACf,mBAAmB;IACnB,yBAAyB;IACzB,eAAe;IACf,WAAW;IACX,aAAa;IACb,6BAA6B;IAC7B,eAAe;AACnB;;AAEA;IACI,uBAAuB;AAC3B","sourcesContent":[".loginA{\n    background-color:  #B7D6ED;\n    font-family: \"Kumbh Sans\";\n    width: 100%;\n    height: 100vh;\n    justify-content: center;\n    align-items: center;\n    display: 'flex';\n    flex-direction: 'column';\n}\n\n.loginB{\n\n    width: 100%;\n    height: 95vh;\n}\n\n\n.logo{\n    width: 100%;\n    height: 40%;\n    justify-content: center;\n    margin-top: 10%;\n}\n\n.login{\n    background-color:  white;\n    width: 18%;\n    height: 8%;\n    justify-content: center;\n    align-items: center;\n    color: black;\n    margin-left: 40%;\n    border-radius: 20px;\n    font-family: \"Kumbh Sans\";\n}\n\n.login:hover{\n    background-color:  gray;\n}\n\n.deconnexion{\n    background-color:  white;\n    width: 25%;\n    height: 20%;\n    justify-content: center;\n    align-items: center;\n    color: black;\n    margin-left: 0%;\n    border-radius: 20px;\n    font-family: \"Kumbh Sans\";\n    margin-top: 60%;\n    padding: 5%;\n    display: flex;\n    border: 1px solid transparent;\n    cursor: pointer;\n}\n\n.deconnexion:hover{\n    background-color:  gray;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
