// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  display: flex;
  height: 97vh;
}

.main-content {
  flex-grow: 1;
  background-color: #b7d6ed;
  width: 70%;
}

.logo-bar {
  text-align: center;
  margin: 0px;
}

.logo {
  font-size: 36px;
  font-weight: bold;
  color: #333;
  font-family: "Kumbh Sans", sans-serif;
  width: 700px;
  height: 150px;
}

.titre {
  font-size: 2vw;
  text-align: left;
  margin-left: 6vw;
  color: #305E7E;
  border-bottom: 1px solid white;
  padding-bottom: 1vh;
  margin-bottom: 5vh;
  font-family: "Kumbh Sans", sans-serif;
  font-weight: 510;
}

.diolist {
  font-size: 10vw;
}

.wallet {
  font-size: 28px;
  text-align: left;
  margin-left: 60px;
  color: #305E7E;
  border-bottom: 1px solid white;
  padding-bottom: 10px;
  margin-bottom: 30px;
  font-family: "Kumbh Sans", sans-serif;
  font-weight: 510;
  margin-top: 90px;
}

.DIOList-container {
  margin-left: 20px;
  margin-bottom: 2vh;
  margin-top: 2vh;
}

.DIO-item {
  background-color: white;
  padding: 20px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.diolist-container {
  max-height: 420px;
  overflow-y: auto;
  margin-bottom: 1vh;
}
`, "",{"version":3,"sources":["webpack://./src/pages/HomePage/Homepage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,yBAAyB;EACzB,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,WAAW;EACX,qCAAqC;EACrC,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,gBAAgB;EAChB,cAAc;EACd,8BAA8B;EAC9B,mBAAmB;EACnB,kBAAkB;EAClB,qCAAqC;EACrC,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,cAAc;EACd,8BAA8B;EAC9B,oBAAoB;EACpB,mBAAmB;EACnB,qCAAqC;EACrC,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,uBAAuB;EACvB,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":[".App {\n  display: flex;\n  height: 97vh;\n}\n\n.main-content {\n  flex-grow: 1;\n  background-color: #b7d6ed;\n  width: 70%;\n}\n\n.logo-bar {\n  text-align: center;\n  margin: 0px;\n}\n\n.logo {\n  font-size: 36px;\n  font-weight: bold;\n  color: #333;\n  font-family: \"Kumbh Sans\", sans-serif;\n  width: 700px;\n  height: 150px;\n}\n\n.titre {\n  font-size: 2vw;\n  text-align: left;\n  margin-left: 6vw;\n  color: #305E7E;\n  border-bottom: 1px solid white;\n  padding-bottom: 1vh;\n  margin-bottom: 5vh;\n  font-family: \"Kumbh Sans\", sans-serif;\n  font-weight: 510;\n}\n\n.diolist {\n  font-size: 10vw;\n}\n\n.wallet {\n  font-size: 28px;\n  text-align: left;\n  margin-left: 60px;\n  color: #305E7E;\n  border-bottom: 1px solid white;\n  padding-bottom: 10px;\n  margin-bottom: 30px;\n  font-family: \"Kumbh Sans\", sans-serif;\n  font-weight: 510;\n  margin-top: 90px;\n}\n\n.DIOList-container {\n  margin-left: 20px;\n  margin-bottom: 2vh;\n  margin-top: 2vh;\n}\n\n.DIO-item {\n  background-color: white;\n  padding: 20px;\n  margin-bottom: 15px;\n  border: 1px solid #ccc;\n  border-radius: 5px;\n}\n\n.diolist-container {\n  max-height: 420px;\n  overflow-y: auto;\n  margin-bottom: 1vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
