// fetchDioData.js
import axios from 'axios';

const fetchDioData = async (setDioData, setLoading, setError) => {
  try {
    const userId = localStorage.getItem('userId');
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/DIO/dioData/${userId}`);
    const formattedData = response.data.map((item) => ({
      id: item.id,
      name: item.nom_do,
      description: item.do_description,
      type: item.type,
      id_ceo: item.id_ceo,
      do_creator: {
        name: item.do_creator_name,
        first_name: item.do_creator_first_name,
      },
      member: {
        name: item.member_name,
        email: item.member_email,
        first_name: item.member_first_name,
        phone: item.member_phone,
        address: item.member_adresse,
      },
    }));
    setDioData(formattedData);
    setLoading(false);
  } catch (error) {
    setError("Erreur lors de la récupération des données DIO.");
    setLoading(false);
  }
};

export default fetchDioData;
