import React from 'react';

const ProjectFilter = () => {
  return (
    <div className="relative">
      <button className="w-[80%] px-6 py-3 rounded-xl border-2 border-black font-mono text-left flex items-center justify-between uppercase mb-4">
        <span>FILTRER bay : All my collab spaces</span>
        <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M19 9l-7 7-7-7" />
        </svg>
      </button>
    </div>
  );
};

export default ProjectFilter;