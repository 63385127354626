import React from 'react';

const Rating = ({ label, rating, points, bg }) => {
  return (
    <div className="flex items-center justify-between mb-2">
      <span className="text-gains3 uppercase mr-4">{label}</span>
      <div className="flex">
        {[...Array(points)].map((_, index) => (
          <div
            key={index}
            className={`w-4 h-4 rounded-full mr-2 ${
              index < rating ? 'bg-gains3' : 'bg-transparent border border-gains3'
            }`}
          ></div>
        ))}
      </div>
    </div>
  );
};




export default Rating