import React, { useState, useEffect, forwardRef } from "react";
import mourad from "../../../images/MK.png";
import guillaume from "../../../images/GH.png";
import oussman from "../../../images/OD.png";
import romain from "../../../images/RL.png";

import heart from "../../../images/heart2.png";

const FilterStatusCards = forwardRef(
  ({ handleCardClick, isSplitView, setStatus }, ref) => {
    const [shouldScroll, setShouldScroll] = useState(false);
    const cardsData = [
      {
        id: 1,
        title: "Collab Space",
        subtitle: "Complete to earn your Thanks",
        opportunities: "My ongoing actions",
        heartCount: 2,
        date: "3 J",
        profilePics: [mourad],
        profilNames: ["Mourad"],
        bgColors: ["bg-orange", "bg-orange1", "bg-orange2", "bg-orange3"],
        callToAction: "ACHIEVE IT",
        totalThanks: "1260",
        status: "On going",
      },
      {
        id: 2,
        title: "Collab Space",
        subtitle: "Seize new Thanks, Achieve more",
        opportunities: "My work opportunities",
        heartCount: 150,
        date: "6 J",
        profilePics: [oussman],
        profilNames: ["Oussman"],
        bgColors: [
          "bg-softGreen",
          "bg-softGreen1",
          "bg-softGreen2",
          "bg-softGreen3",
        ],
        callToAction: "I WANT IT",
        totalThanks: "34260",
        status: "Open",
      },
      {
        id: 3,
        title: "Collab Space",
        subtitle: "Give Feedback, Earn new Thanks",
        opportunities: "My feedback-to-provide",
        heartCount: 6,
        date: "2 J",
        profilePics: [mourad, romain],
        profilNames: ["Mourad", "Romain"],
        bgColors: ["bg-aqua", "bg-aqua1", "bg-aqua2", "bg-aqua3"],
        callToAction: "MY FEEDBACK",
        totalThanks: "8400",
        status: "In review",
      },
      {
        id: 4,
        title: "Collab Space",
        subtitle: "My achievements and gains",
        opportunities: "My achievements",
        heartCount: "860",
        date: "28 10",
        profilePics: [guillaume, mourad],
        profilNames: ["Guillaume", "Mourad"],
        bgColors: ["bg-gains", "bg-gains1", "bg-gains2", "bg-gains3"],
        callToAction: "COLLECT",
        totalThanks: "24000",
        status: "Achieved",
      },
    ];
    useEffect(() => {
      if (shouldScroll && ref.current) {
        ref.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        setShouldScroll(false);
      }
    }, [shouldScroll, ref]);

    return (
      <div
        ref={ref}
        className={`
        ${isSplitView ? "w-[94%] max-w-[1076px] mx-auto px-4" : "w-[84%] ml-20"} 
        medium:flex medium:flex-wrap 
        ${
          isSplitView
            ? "justify-center gap-14"
            : "justify-around medium:justify-around extraLarge:justify-between"
        }
        cursor-pointer
        mt-20
      `}
      >
        {cardsData.map((card) => (
          <div
            key={card.id}
            onClick={() => {
              handleCardClick(card.status);
              setStatus(card.status);
              setShouldScroll(true);
            }}
            className="relative mb-32 small:ml-28 large:m-0 w-1/2 medium:w-1/3 large:w-1/6 justify-center"
          >
            {card.bgColors.map((bgColor, index) => (
              <div
                key={index}
                className={`absolute rounded-[1rem] ${isSplitView ? "w-[106px] h-[150px]" : "w-[140px] h-[180px]"} border-2 border-white ${bgColor} ${
                  isSplitView
                    ? index === 0
                      ? "left-[30px]"
                      : index === 1
                        ? "left-[15px]"
                        : index === 2
                          ? "left-[0px]"
                          : "-left-[15px]"
                    : index === 0
                      ? "left-[48px]"
                      : index === 1
                        ? "left-[24px]"
                        : index === 2
                          ? "left-[0px]"
                          : "-left-[24px]"
                }`}
              />
            ))}
            {card.id === 4 ? (
              <div className="relative">
                {card.bgColors.map((bgColor, index) => (
                  <div
                    key={index}
                    className={`absolute rounded-[1rem]  border-2 border-white ${bgColor} ${isSplitView ? "w-[106px] h-[150px]" : "w-[140px] h-[180px]"} border-2 ${
                      isSplitView
                        ? index === 0
                          ? "left-[30px]"
                          : index === 1
                            ? "left-[15px]"
                            : index === 2
                              ? "left-[0px]"
                              : "-left-[15px]"
                        : index === 0
                          ? "left-[48px]"
                          : index === 1
                            ? "left-[24px]"
                            : index === 2
                              ? "left-[0px]"
                              : "-left-[24px]"
                    }`}
                  />
                ))}
                <div
                  className={`relative bg-gains3 border-r-2 border-white rounded-[1rem] ${isSplitView ? "p-4 w-[106px] h-[150px] -left-[15px]" : "p-4 w-[140px] h-[180px] -left-[24px]"}`}
                >
                  <div
                    className={`${isSplitView ? "w-[60px] h-[28px] rounded-[10px]" : "w-[82px] h-[40px] rounded-[12px]"} flex items-center justify-center bg-stone-950   mx-auto my-2`}
                  >
                    <p
                      className={`${isSplitView ? "text-lg" : "text-3xl"} text-gains3  text-center font-silka-mono`}
                    >
                      {card.heartCount}
                    </p>
                    <img
                      className={`${isSplitView ? "w-4 h-4" : "w-4 h-4"} 'p-1'`}
                      alt="Thanks"
                      src={heart}
                    />
                  </div>
                  <h3
                    className={`${isSplitView ? "text-[6px]" : "text-[8px]"} font-silka-mono text-[6px] pt-1 mt-2`}
                  >
                    {card.title}
                  </h3>
                  <p
                    className={`font-silka-mono text-[10px] font-semibold overflow-hidden  ${isSplitView ? "text-[8px]" : "text-[10px]"}`}
                    style={{
                      display: "-webkit-box",
                      WebkitLineClamp: 3,
                      WebkitBoxOrient: "vertical",
                      height: isSplitView ? "2.4rem" : "3rem",
                      lineHeight: isSplitView ? "0.7rem" : "1rem",
                    }}
                  >
                    {card.subtitle}
                  </p>
                  {/* <div className="flex items-center space-x-2">
                    {card.profilNames.map((name, index) => (
                      <div key={index} className="flex items-center mt-1">
                        <img
                          src={card.profilePics[index]}
                          alt={`Profile ${index}`}
                          className={`rounded-full ${isSplitView ? "w-3 h-3" : "w-4 h-4"}`}
                        />
                        <span
                          className={`${isSplitView ? "text-[5px]" : "text-[6px]"} font-silka-mono pt-1 pl-1`}
                        >
                          {name}
                        </span>
                        {index < card.profilNames.length - 1 && (
                          <span
                            className={`${isSplitView ? "text-[8px]" : "text-[10px]"} font-silka-mono`}
                          >
                            {" "}
                            &gt;{" "}
                          </span>
                        )}
                      </div>
                    ))}
                  </div> */}
                </div>
                <div className={`${isSplitView ? "w-[120px]" : "w-[140px]"}`}>
                  <p
                    className={`${isSplitView ? "text-[10px] mt-[10px]" : "text-xs mt-4 transform -translate-x-2 -translate-y-2"}  font-silka-mono `}
                  >
                    {card.opportunities}
                  </p>
                </div>
                <div className="flex justify-start mt-4">
                  {/* <img className={`${isSplitView ? 'w-4 h-4': 'w-6 h-6'} 'p-1' mr-2`} alt="Thanks" src={heart} /> 
                            <p className="text-sm font-silka-mono">{card.totalThanks}</p> */}
                </div>
              </div>
            ) : (
              <div
                className={`relative ${card.bgColors[3]} border-r-2 border-white rounded-[1rem] ${isSplitView ? "p-4 w-[106px] h-[150px] -left-[15px]" : "p-4 w-[140px] h-[180px] -left-[24px]"}`}
              >
                <div className="flex justify-between">
                  <div
                    className={`${isSplitView ? "h-[25px] w-[8px] text-[6px]" : "h-[28px] w-[10px] text-[6.5px]"} font-silka-mono flex items-center justify-center  text-white bg-stone-950 rounded-[5px] pl-2 pr-2 `}
                  >
                    {card.date.split(" ")[0]} <br /> {card.date.split(" ")[1]}
                  </div>
                  <div className="relative">
                    <img
                      alt="thanks"
                      className={`${isSplitView ? "w-2 h-2" : "w-3 h-3"} mx-auto block`}
                      src={heart}
                    />
                    {/* <p className="font-silka-mono text-[7px] text-gray-500 text-center mt-1">{card.heartCount}</p> */}
                  </div>
                </div>
                <h3 className="font-silka-mono text-[8px] pt-1 mt-4">
                  {card.title}
                </h3>
                <p
                  className={`font-silka-mono text-[10px] font-semibold overflow-hidden  ${isSplitView ? "text-[8px]" : "text-[10px]"}`}
                  style={{
                    display: "-webkit-box",
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: "vertical",
                    height: isSplitView ? "2.2rem" : "3rem",
                    lineHeight: isSplitView ? "0.7rem" : "1rem",
                  }}
                >
                  {card.subtitle}
                </p>
                {/* <div className="flex items-center space-x-2">
                  {card.profilNames.map((name, index) => (
                    <div key={index} className="flex items-center mt-1">
                      <img
                        src={card.profilePics[index]}
                        alt={`Profile ${index}`}
                        className={`rounded-full ${isSplitView ? "w-3 h-3" : "w-4 h-4"}`}
                      />
                      <span
                        className={`${isSplitView ? "text-[5px]" : "text-[6px]"} font-silka-mono pt-1 pl-1`}
                      >
                        {name}
                      </span>
                      {index < card.profilNames.length - 1 && (
                        <span
                          className={`${isSplitView ? "text-[8px]" : "text-[10px]"} font-silka-mono`}
                        >
                          &gt;
                        </span>
                      )}
                    </div>
                  ))}
                </div> */}
                <p
                  className={`${isSplitView ? "text-[6.8px] mt-2 font-semibold" : "text-[8px] mt-3 font-bold"} font-silka-mono  underline`}
                >
                  {card.callToAction}
                </p>
                <div className="w-[140px]">
                  <p
                    className={`${isSplitView ? "text-[10px] mt-7" : "text-xs mt-10"} font-silka-mono`}
                  >
                    {card.opportunities}
                  </p>
                </div>
                <div className="flex justify-start mt-4">
                  {/* <img className={`${isSplitView ? 'w-4 h-4': 'w-6 h-6'} 'p-1' mr-2`} alt="Thanks" src={heart} /> 
                            <p className="text-sm font-silka-mono">{card.totalThanks}</p> */}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  },
);

export default FilterStatusCards;
