import React from 'react';
import ProjectCard from './ProjectCard';
import FilterButton from './FilterButton';
import ProjectFilter from './ProjectFilter';
import arrow from '../../../../images/icones/arrow.png';
import useStore from "../../../../API/store";

function FilterView({handleGoBack, handleCardClick, selectedStatus}) {
  const {
    onGoingCount,
    openCount,
    inReviewCount,
    achievedCount,
  } = useStore();
    return (
        <div className="w-1/2 bg-rightGreyBg p-32 overflow-y-auto max-h-screen">
          <div className="max-w-6xl twoXl:max-w-4xl mx-auto space-y-12">
            <img
          src={arrow}
          alt="retour"
          className="w-12 h-12 cursor-pointer"
          onClick={handleGoBack}
            />
            <section>
              <h2 className="font-mono text-2xl mb-10 mt-10">By Project</h2>
              <ProjectFilter />
              
              <div className="grid grid-cols-1 medium:grid-cols-2 extraLarge:grid-cols-3 twoXl:grid-cols-4 gap-4 mt-4">
                <ProjectCard number="45" organization="66ORIGIN" title="Projet 01" />
                <ProjectCard number="45" organization="QUIPO" title="Projet 02" />
                <ProjectCard number="45" organization="THANKWARD" title="Projet 03" />
                <ProjectCard number="45" organization="66ORIGIN" title="Projet 04" />
              </div>
            </section>
    
            <section>
              <h2 className="font-mono text-2xl mb-4 mt-10">By Status</h2>
              <div className="flex flex-wrap gap-3">
                <FilterButton active={selectedStatus === 'On going'}  onClick={() => handleCardClick('On going')} count={onGoingCount}>On going</FilterButton>
                <FilterButton active={selectedStatus === 'Open'}  onClick={() => handleCardClick('Open')} count={openCount}>Open</FilterButton>
                <FilterButton active={selectedStatus === 'In review'}  onClick={() => handleCardClick('In review')} count={inReviewCount}>Feedback</FilterButton>
                <FilterButton active={selectedStatus === 'Achieved'}  onClick={() => handleCardClick('Achieved')} count={achievedCount}>Achieved</FilterButton>
              </div>
            </section>
          </div>
        </div>
      );
}

export default FilterView;