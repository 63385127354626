// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.submition-pop-up {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(114, 114, 114, 0.8);
    width: 30vw;
    height: 25vh;
    border-radius: 10px;
    padding: 20px;
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  }
  
  .submitButton {
    background-color: #305E7E;
    border: none;
    color: white;

    width: 25vw; 
    height: 6vh; 
    text-align: center;
    font-size: 1.2vw;
    margin: 0.4vh 0.2vw;
    cursor: pointer;
    border-radius: 1vw;
    font-family: 'Kumbh Sans', sans-serif;
  }

  .submitButton2 {
    background-color: gray;
    border: none;
    color: white;

    width: 25vw; 
    height: 6vh; 
    text-align: center;
    font-size: 1.2vw; 
    margin: 0.4vh 0.2vw; 
    cursor: pointer;
    border-radius: 1vw;
    font-family: 'Kumbh Sans', sans-serif;
  }
  
  .submitButton:hover {
    color: white; 
    background-color: gray; 
  }

  .who{
    font-size: 4vh;
    font-weight: 700;
    font-family: 'Kumbh Sans', sans-serif;
  }`, "",{"version":3,"sources":["webpack://./src/Components/PopUp/ExecutionCreationPopUp/PopupFeed.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,0CAA0C;IAC1C,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,aAAa;IACb,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,6BAA6B;IAC7B,mBAAmB;IACnB,gDAAgD;EAClD;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,YAAY;;IAEZ,WAAW;IACX,WAAW;IACX,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,eAAe;IACf,kBAAkB;IAClB,qCAAqC;EACvC;;EAEA;IACE,sBAAsB;IACtB,YAAY;IACZ,YAAY;;IAEZ,WAAW;IACX,WAAW;IACX,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,eAAe;IACf,kBAAkB;IAClB,qCAAqC;EACvC;;EAEA;IACE,YAAY;IACZ,sBAAsB;EACxB;;EAEA;IACE,cAAc;IACd,gBAAgB;IAChB,qCAAqC;EACvC","sourcesContent":[".submition-pop-up {\n    position: absolute;\n    top: 40%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    background-color: rgba(114, 114, 114, 0.8);\n    width: 30vw;\n    height: 25vh;\n    border-radius: 10px;\n    padding: 20px;\n    z-index: 100;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-around;\n    align-items: center;\n    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);\n  }\n  \n  .submitButton {\n    background-color: #305E7E;\n    border: none;\n    color: white;\n\n    width: 25vw; \n    height: 6vh; \n    text-align: center;\n    font-size: 1.2vw;\n    margin: 0.4vh 0.2vw;\n    cursor: pointer;\n    border-radius: 1vw;\n    font-family: 'Kumbh Sans', sans-serif;\n  }\n\n  .submitButton2 {\n    background-color: gray;\n    border: none;\n    color: white;\n\n    width: 25vw; \n    height: 6vh; \n    text-align: center;\n    font-size: 1.2vw; \n    margin: 0.4vh 0.2vw; \n    cursor: pointer;\n    border-radius: 1vw;\n    font-family: 'Kumbh Sans', sans-serif;\n  }\n  \n  .submitButton:hover {\n    color: white; \n    background-color: gray; \n  }\n\n  .who{\n    font-size: 4vh;\n    font-weight: 700;\n    font-family: 'Kumbh Sans', sans-serif;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
