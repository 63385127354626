import React, { useState } from "react";
import mourad from "../../../../images/MK.png";
import guillaume from "../../../../images/GH.png";
import heart from "../../../../images/heart2.png";
import exit from "../../../../images/icones/esc.png";

const Modal = ({ onClose, onRecover }) => {
  const [cardsData, setCardsData] = useState([
    {
      id: 4,
      title: "Thankward by MKIF",
      subtitle: 'intégrer le "Bayesian Truth Serum" à l’algorithme de Thanks',
      opportunities: "My treasure",
      heartCount: 860,
      date: "28 10",
      profilePics: [guillaume, mourad],
      profilNames: ["Guillaume", "Mourad"],
      callToAction: "COLLECT",
    },
  ]);

  const handleRecoverClick = () => {
    const startCount = cardsData[0].heartCount;
    let currentCount = startCount;

    const interval = setInterval(() => {
      currentCount -= 10;

      if (currentCount <= 0) {
        clearInterval(interval);
        currentCount = 0;
        onRecover(startCount);
      }

      setCardsData([{ ...cardsData[0], heartCount: currentCount }]);
    }, 10);
  };

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-30"
      onClick={onClose}
    >
      <div
        className="bg-black p-10 rounded-3xl shadow-lg w-full max-w-3xl h-[560px] "
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between">
          <img onClick={onClose} alt="exit" src={exit} />
          <button
            className="font-silka-mono text-slate-300 text-[10px] rounded uppercase underline"
            onClick={handleRecoverClick}
          >
            Collect all my <br /> Thanks at once
          </button>
        </div>
        <div className="flex flex-col justify-around min-h-[420px]">
          <p className="mb-4 mt-2 text-white text-center text-2xl font-silka-mono">
            1 new achievement <br /> Rewarded !!!
          </p>
          <div className="flex justify-center items-center">
            <div className="bg-gains3 border-r-2 border-white rounded-[1rem] w-[162px] h-[216px] p-4">
              <div className="flex items-center justify-center bg-stone-950 rounded-[12px] w-[90px] h-[43px] mx-auto my-2">
                <p className="text-gains3 text-3xl text-center font-silka-mono">
                  {cardsData[0].heartCount}
                </p>
                <img className="w-7 h-7 p-1" alt="Thanks" src={heart} />
              </div>
              <h3 className="font-silka-mono text-[8px] pt-1 mt-5">
                {cardsData[0].title}
              </h3>
              <p
                className="font-silka-mono text-[12px] font-semibold overflow-hidden mt-2"
                style={{
                  display: "-webkit-box",
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: "vertical",
                  height: "3rem",
                  lineHeight: "1rem",
                }}
              >
                {cardsData[0].subtitle}
              </p>
              <div className="flex items-center space-x-2">
                {cardsData[0]?.profilNames.map((name, index) => (
                  <div key={index} className="flex items-center">
                    <img
                      src={cardsData[0].profilePics[index]}
                      alt={`Profile ${index}`}
                      className="w-4 h-4 rounded-full mt-3"
                    />
                    <span className="text-[8px] font-silka-mono pt-1 pl-1 mt-2">
                      {name}
                    </span>
                    {index < cardsData[0].profilNames.length - 1 && (
                      <span className="text-[10px] font-silka-mono font-lg m-1 mt-3">
                        &gt;
                      </span>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="flex justify-center">
            <button
              className="font-silka-mono text-white px-4 py-2 rounded uppercase underline"
              onClick={handleRecoverClick}
            >
              Collect
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
