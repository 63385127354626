// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.better{
    margin-top: 10%;
    font-size: 150%; 
    font-weight: 500;   
}

h3{
  font-size: 150%;  
}


.input-feed{
    background-color: rgb(221, 216, 216);
    color: black;
    height: 10vh;
    width: 40vw;
    border-radius: 10px;
    border: 200%;
    margin-left: 10vw;
    margin-right: 10vw;
    margin-top: 2%;
}
.feedback{
    margin-top: 20%;
    font-size: 150%;
}

.input-feed {
    border: none; 
    color: black; 
    font-family: 'Kumbh Sans', sans-serif;
  }
  

  ::placeholder {
    color: black;
  }

  .input-feed::-webkit-input-placeholder {
    color: black;
    font-size: 2.3vh;
  }
  
  .input-feed::-moz-placeholder {
    color: black;
  }
  
  .input-feed:-ms-input-placeholder {
    color: black;
  }`, "",{"version":3,"sources":["webpack://./src/pages/Review/CEOReview/CEONotYet.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,eAAe;IACf,gBAAgB;AACpB;;AAEA;EACE,eAAe;AACjB;;;AAGA;IACI,oCAAoC;IACpC,YAAY;IACZ,YAAY;IACZ,WAAW;IACX,mBAAmB;IACnB,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,cAAc;AAClB;AACA;IACI,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,qCAAqC;EACvC;;;EAGA;IACE,YAAY;EACd;;EAEA;IACE,YAAY;IACZ,gBAAgB;EAClB;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,YAAY;EACd","sourcesContent":[".better{\n    margin-top: 10%;\n    font-size: 150%; \n    font-weight: 500;   \n}\n\nh3{\n  font-size: 150%;  \n}\n\n\n.input-feed{\n    background-color: rgb(221, 216, 216);\n    color: black;\n    height: 10vh;\n    width: 40vw;\n    border-radius: 10px;\n    border: 200%;\n    margin-left: 10vw;\n    margin-right: 10vw;\n    margin-top: 2%;\n}\n.feedback{\n    margin-top: 20%;\n    font-size: 150%;\n}\n\n.input-feed {\n    border: none; \n    color: black; \n    font-family: 'Kumbh Sans', sans-serif;\n  }\n  \n\n  ::placeholder {\n    color: black;\n  }\n\n  .input-feed::-webkit-input-placeholder {\n    color: black;\n    font-size: 2.3vh;\n  }\n  \n  .input-feed::-moz-placeholder {\n    color: black;\n  }\n  \n  .input-feed:-ms-input-placeholder {\n    color: black;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
